import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import "../../CSS/Global.css";
import "../../CSS/Quiz.css";

const harryPotterQuestions = [
    {
      question: "Quem é o Príncipe Mestiço?",
      options: ["Harry Potter", "Severo Snape", "Tom Riddle", "Draco Malfoy"],
      answer: 1,
    },
    {
      question: "Qual é a poção que Harry ganha usando as instruções do Príncipe Mestiço?",
      options: ["Felix Felicis", "Amortentia", "Polissuco", "Veritaserum"],
      answer: 0,
    },
    {
      question: "Quem é escolhido para ser o novo capitão do time de Quadribol da Grifinória?",
      options: ["Harry Potter", "Rony Weasley", "Hermione Granger", "Ginny Weasley"],
      answer: 0,
    },
    {
      question: "Qual é o nome do novo professor de Poções em 'O Enigma do Príncipe'?",
      options: ["Horácio Slughorn", "Severo Snape", "Gilderoy Lockhart", "Remo Lupin"],
      answer: 0,
    },
    {
      question: "Qual é o nome do feitiço usado para libertar a poção Felix Felicis da garrafa?",
      options: ["Diffindo", "Aguamenti", "Sectumsempra", "Alohomora"],
      answer: 2,
    },
    {
      question: "Qual é a bebida envenenada que quase mata Ron?",
      options: ["Suco de Abóbora", "Hidromel", "Cerveja Amanteigada", "Vinho"],
      answer: 1,
    },
    {
      question: "Quem descobre o armário sumidouro usado por Draco para trazer os Comensais da Morte para Hogwarts?",
      options: ["Harry Potter", "Rony Weasley", "Hermione Granger", "Filch"],
      answer: 0,
    },
    {
      question: "Qual é o nome do objeto que Dumbledore procura com Harry em uma caverna?",
      options: ["Um Medalhão", "Uma Taça", "Um Anel", "Uma Varinha"],
      answer: 0,
    },
    {
      question: "O que Draco Malfoy conserta no filme 'O Enigma do Príncipe'?",
      options: ["O Armário Sumidouro", "A Sala Precisa", "A Varinha de Sabugueiro", "O Espelho de Ojesed"],
      answer: 0,
    },
    {
      question: "Qual maldição Severo Snape lança para matar Dumbledore?",
      options: ["Avada Kedavra", "Crucio", "Imperio", "Sectumsempra"],
      answer: 0,
    },
    {
      question: "Quem encontra o livro de Poções do Príncipe Mestiço?",
      options: ["Harry Potter", "Hermione Granger", "Ginny Weasley", "Rony Weasley"],
      answer: 0,
    },
    {
      question: "Qual é o nome verdadeiro de Lord Voldemort?",
      options: ["Tom Marvolo Riddle", "Tom Alvo Dumbledore", "Tom Sirius Potter", "Tom Bartô Crouch"],
      answer: 0,
    },
    {
      question: "Quem envenena o hidromel destinado a Dumbledore?",
      options: ["Draco Malfoy", "Severo Snape", "Slughorn", "Lord Voldemort"],
      answer: 0,
    },
    {
      question: "Quem é o alvo de uma tentativa de assassinato em Hogwarts?",
      options: ["Dumbledore", "Rony Weasley", "Harry Potter", "Hermione Granger"],
      answer: 1,
    },
    {
      question: "Qual feitiço Severo Snape ensina Harry que o ajuda a se proteger contra os Dementadores?",
      options: ["Sectumsempra", "Expecto Patronum", "Expelliarmus", "Crucio"],
      answer: 2,
    },
  ];
  

const HarryPotterPrinceQuiz: React.FC = () => {
  const [shuffledQuestions, setShuffledQuestions] = useState<any[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [score, setScore] = useState(0);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [isQuizFinished, setIsQuizFinished] = useState(false);

  function shuffleOptions(options: string[]): string[] {
    const shuffledOptions = [...options];
    for (let i = shuffledOptions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledOptions[i], shuffledOptions[j]] = [shuffledOptions[j], shuffledOptions[i]];
    }
    return shuffledOptions;
  }

  useEffect(() => {
    const shuffled = harryPotterQuestions.map((question) => {
      const options = shuffleOptions(question.options);
      const correctAnswerIndex = options.indexOf(question.options[question.answer]);
      return {
        ...question,
        options,
        answer: correctAnswerIndex,
      };
    });
    setShuffledQuestions(shuffled);
  }, []);

  const handleOptionSelect = (index: number) => {
    if (!isAnswerSubmitted) {
      setSelectedOption(index);
    }
  };

  const handleSubmitAnswer = () => {
    if (selectedOption !== null) {
      const isCorrect = selectedOption === shuffledQuestions[currentQuestionIndex].answer;
      if (isCorrect) {
        setScore((prevScore) => prevScore + 1);
      }
      setIsAnswerSubmitted(true);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < shuffledQuestions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedOption(null);
      setIsAnswerSubmitted(false);
    } else {
      setIsQuizFinished(true);
    }
  };

  const handlePlayAgain = () => {
    window.location.reload(); // Recarrega a página para reiniciar o quiz
  };

  const handleGoBack = () => {
    window.location.href = "/quiz"; // Redireciona para a rota "/quiz"
  };

  return (
    <div>
      <div className="header">
        <Header activePage="quiz" />
      </div>

      <div className="quiz-container">
        {!isQuizFinished ? (
          shuffledQuestions.length > 0 && (
            <>
              <h2>{shuffledQuestions[currentQuestionIndex].question}</h2>
              <div className="options">
                {shuffledQuestions[currentQuestionIndex].options.map(
                  (option: string, index: number) => (
                    <button
                      key={index}
                      className={`option-button ${
                        selectedOption === index ? "selected" : ""
                      } ${
                        isAnswerSubmitted
                          ? index === shuffledQuestions[currentQuestionIndex].answer
                            ? "correct"
                            : index === selectedOption
                            ? "incorrect"
                            : ""
                          : ""
                      }`}
                      onClick={() => handleOptionSelect(index)}
                      disabled={isAnswerSubmitted}
                    >
                      {option}
                    </button>
                  )
                )}
              </div>
              {isAnswerSubmitted ? (
                <button className="next-button" onClick={handleNextQuestion}>
                  Próxima
                </button>
              ) : (
                <button
                  className="submit-button"
                  onClick={handleSubmitAnswer}
                  disabled={selectedOption === null}
                >
                  Confirmar
                </button>
              )}
            </>
          )
        ) : (
          <div className="quiz-modal">
            <div className="quiz-modal-content">
              <h2>Quiz Concluído!</h2>
              <p>Sua pontuação: {((score / shuffledQuestions.length) * 100).toFixed(2)}%</p>
              <div className="modal-buttons">
                <button onClick={handlePlayAgain} className="modal-button">
                  Jogar Novamente
                </button>
                <button onClick={handleGoBack} className="modal-button">
                  Voltar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default HarryPotterPrinceQuiz;
