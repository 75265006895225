import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import "../../CSS/Global.css";
import "../../CSS/Quiz.css";

const harryPotterQuestions = [
  {
    question: "Quem entrega a carta de admissão de Harry para Hogwarts?",
    options: ["Hagrid", "Dumbledore", "McGonagall", "Snape"],
    answer: 0,
  },
  {
    question: "Qual é o nome completo de Harry Potter?",
    options: ["Harry James Potter", "Harry Sirius Potter", "Harry Albus Potter", "Harry Severus Potter"],
    answer: 0,
  },
  {
    question: "Qual é o nome do banco de Gringotes?",
    options: ["Gringotes", "Banco de Bruxos", "Banco de Hogwarts", "Banco dos Elfos"],
    answer: 0,
  },
  {
    question: "Quem é o guarda-caça de Hogwarts?",
    options: ["Hagrid", "Flitwick", "Filch", "Snape"],
    answer: 0,
  },
  {
    question: "Qual é o nome do cachorro de Hagrid?",
    options: ["Fofo", "Canino", "Fang", "Sirius"],
    answer: 1,
  },
  {
    question: "Qual é o número da plataforma onde Harry pega o trem para Hogwarts?",
    options: ["9 ¾", "10", "8 ½", "7 ⅔"],
    answer: 0,
  },
  {
    question: "Quem é o primeiro amigo de Harry em Hogwarts?",
    options: ["Hermione Granger", "Ron Weasley", "Draco Malfoy", "Neville Longbottom"],
    answer: 1,
  },
  {
    question: "Qual é o nome do chapéu que seleciona as casas em Hogwarts?",
    options: ["Chapéu Selecionador", "Chapéu Seletor", "Chapéu Seletivo", "Chapéu de Hogwarts"],
    answer: 1,
  },
  {
    question: "Qual é o nome do professor de Poções?",
    options: ["Severo Snape", "Minerva McGonagall", "Filius Flitwick", "Quirino Quirrell"],
    answer: 0,
  },
  {
    question: "Qual é o nome do vilão no primeiro livro?",
    options: ["Voldemort", "Lucius Malfoy", "Draco Malfoy", "Barty Crouch"],
    answer: 0,
  },
  {
    question: "Qual é o objeto que Harry pega em seu primeiro jogo de Quadribol?",
    options: ["Goles", "Balaço", "Pomo de Ouro", "Vassoura"],
    answer: 2,
  },
  {
    question: "Quem salva Harry de Voldemort na Floresta Proibida?",
    options: ["Hagrid", "Ron Weasley", "Firenze", "Hermione Granger"],
    answer: 2,
  },
  {
    question: "Qual é o nome do espelho mágico que Harry encontra?",
    options: ["Espelho de Erised", "Espelho das Almas", "Espelho de Veritas", "Espelho de Sonserina"],
    answer: 0,
  },
  {
    question: "Quem é o fantasma da casa Grifinória?",
    options: ["Nick Quase Sem Cabeça", "Pirraça", "Myrtle", "O Barão Sangrento"],
    answer: 0,
  },
  {
    question: "Qual feitiço é usado para derrotar o trasgo no banheiro das meninas?",
    options: ["Wingardium Leviosa", "Lumos", "Expelliarmus", "Alohomora"],
    answer: 0,
  },
];

const HarryPotterQuiz: React.FC = () => {
  const [shuffledQuestions, setShuffledQuestions] = useState<any[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [score, setScore] = useState(0);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [isQuizFinished, setIsQuizFinished] = useState(false);

  function shuffleOptions(options: string[]): string[] {
    const shuffledOptions = [...options];
    for (let i = shuffledOptions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledOptions[i], shuffledOptions[j]] = [shuffledOptions[j], shuffledOptions[i]];
    }
    return shuffledOptions;
  }

  useEffect(() => {
    const shuffled = harryPotterQuestions.map((question) => {
      const options = shuffleOptions(question.options);
      const correctAnswerIndex = options.indexOf(question.options[question.answer]);
      return {
        ...question,
        options,
        answer: correctAnswerIndex,
      };
    });
    setShuffledQuestions(shuffled);
  }, []);

  const handleOptionSelect = (index: number) => {
    if (!isAnswerSubmitted) {
      setSelectedOption(index);
    }
  };

  const handleSubmitAnswer = () => {
    if (selectedOption !== null) {
      const isCorrect = selectedOption === shuffledQuestions[currentQuestionIndex].answer;
      if (isCorrect) {
        setScore((prevScore) => prevScore + 1);
      }
      setIsAnswerSubmitted(true);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < shuffledQuestions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedOption(null);
      setIsAnswerSubmitted(false);
    } else {
      setIsQuizFinished(true);
    }
  };

  const handlePlayAgain = () => {
    window.location.reload(); // Recarrega a página para reiniciar o quiz
  };

  const handleGoBack = () => {
    window.location.href = "/quiz"; // Redireciona para a rota "/quiz"
  };

  return (
    <div>
      <div className="header">
        <Header activePage="quiz" />
      </div>

      <div className="quiz-container">
        {!isQuizFinished ? (
          shuffledQuestions.length > 0 && (
            <>
              <h2>{shuffledQuestions[currentQuestionIndex].question}</h2>
              <div className="options">
                {shuffledQuestions[currentQuestionIndex].options.map(
                  (option: string, index: number) => (
                    <button
                      key={index}
                      className={`option-button ${
                        selectedOption === index ? "selected" : ""
                      } ${
                        isAnswerSubmitted
                          ? index === shuffledQuestions[currentQuestionIndex].answer
                            ? "correct"
                            : index === selectedOption
                            ? "incorrect"
                            : ""
                          : ""
                      }`}
                      onClick={() => handleOptionSelect(index)}
                      disabled={isAnswerSubmitted}
                    >
                      {option}
                    </button>
                  )
                )}
              </div>
              {isAnswerSubmitted ? (
                <button className="next-button" onClick={handleNextQuestion}>
                  Próxima
                </button>
              ) : (
                <button
                  className="submit-button"
                  onClick={handleSubmitAnswer}
                  disabled={selectedOption === null}
                >
                  Confirmar
                </button>
              )}
            </>
          )
        ) : (
          <div className="quiz-modal">
            <div className="quiz-modal-content">
              <h2>Quiz Concluído!</h2>
              <p>Sua pontuação: {((score / shuffledQuestions.length) * 100).toFixed(2)}%</p>
              <div className="modal-buttons">
                <button onClick={handlePlayAgain} className="modal-button">
                  Jogar Novamente
                </button>
                <button onClick={handleGoBack} className="modal-button">
                  Voltar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HarryPotterQuiz;