import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import "../../CSS/Global.css";
import "../../CSS/Quiz.css";

const harryPotterQuestions = [
  // Perguntas relacionadas a "Harry Potter e o Prisioneiro de Azkaban"
    {
      question: "Quem é o prisioneiro que escapa de Azkaban?",
      options: ["Sirius Black", "Bellatrix Lestrange", "Lucius Malfoy", "Bartô Crouch Jr."],
      answer: 0,
    },
    {
      question: "Qual é o nome do feitiço que Harry aprende para se defender dos Dementadores?",
      options: ["Expecto Patronum", "Expelliarmus", "Lumos", "Wingardium Leviosa"],
      answer: 0,
    },
    {
      question: "Qual é a forma do Patrono de Harry Potter?",
      options: ["Veado", "Cervo", "Cão", "Lobo"],
      answer: 1,
    },
    {
      question: "Quem é o novo professor de Defesa Contra as Artes das Trevas em 'O Prisioneiro de Azkaban'?",
      options: ["Remo Lupin", "Severo Snape", "Gilderoy Lockhart", "Mad-Eye Moody"],
      answer: 0,
    },
    {
      question: "Quem dá a Harry o Mapa do Maroto?",
      options: ["Fred e Jorge Weasley", "Remo Lupin", "Sirius Black", "Dumbledore"],
      answer: 0,
    },
    {
      question: "O que Sirius Black é para Harry Potter?",
      options: ["Padrinho", "Tio", "Primo", "Irmão"],
      answer: 0,
    },
    {
      question: "Qual é o nome do Hipogrifo que Harry monta em 'O Prisioneiro de Azkaban'?",
      options: ["Bicuço", "Nagini", "Fawkes", "Aragog"],
      answer: 0,
    },
    {
      question: "Qual é a verdadeira identidade de Perebas, o rato de Rony?",
      options: ["Pedro Pettigrew", "Severo Snape", "Sirius Black", "Remo Lupin"],
      answer: 0,
    },
    {
      question: "Qual feitiço Snape ensina aos alunos para combater Dementadores?",
      options: ["Expecto Patronum", "Expelliarmus", "Riddikulus", "Stupefy"],
      answer: 2,
    },
    {
      question: "O que o Mapa do Maroto revela?",
      options: ["Todos os segredos de Hogwarts", "A localização de todos dentro de Hogwarts", "O paradeiro de Voldemort", "O caminho para a Câmara Secreta"],
      answer: 1,
    },
    {
      question: "Quem é capaz de se transformar em um lobisomem?",
      options: ["Remo Lupin", "Sirius Black", "James Potter", "Lucius Malfoy"],
      answer: 0,
    },
    {
      question: "Qual é o nome da planta que ajuda Sirius a escapar da casa dos Black?",
      options: ["Planta do Diabo", "Visgo do Diabo", "Mandrágora", "Mimbulus mimbletonia"],
      answer: 1,
    },
    {
      question: "Quem aparece na bola de cristal de Harry durante a aula de adivinhação?",
      options: ["Sirius Black", "Um cão", "Dementador", "Voldemort"],
      answer: 1,
    },
    {
      question: "Qual é o efeito dos Dementadores quando estão perto?",
      options: ["Causam uma sensação de frio e desespero", "Aumentam a temperatura", "Causam paralisia", "Ajudam as pessoas a ficarem mais felizes"],
      answer: 0,
    },
    {
      question: "Como Harry e Hermione salvam Sirius Black de ser capturado?",
      options: ["Usando o Vira-Tempo", "Usando o Mapa do Maroto", "Lutando contra os Dementadores", "Enganando os Aurores"],
      answer: 0,
    },
  // ... e as outras perguntas listadas acima
];

const HarryPotterAzkabanQuiz: React.FC = () => {
  const [shuffledQuestions, setShuffledQuestions] = useState<any[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [score, setScore] = useState(0);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [isQuizFinished, setIsQuizFinished] = useState(false);

  function shuffleOptions(options: string[]): string[] {
    const shuffledOptions = [...options];
    for (let i = shuffledOptions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledOptions[i], shuffledOptions[j]] = [shuffledOptions[j], shuffledOptions[i]];
    }
    return shuffledOptions;
  }

  useEffect(() => {
    const shuffled = harryPotterQuestions.map((question) => {
      const options = shuffleOptions(question.options);
      const correctAnswerIndex = options.indexOf(question.options[question.answer]);
      return {
        ...question,
        options,
        answer: correctAnswerIndex,
      };
    });
    setShuffledQuestions(shuffled);
  }, []);

  const handleOptionSelect = (index: number) => {
    if (!isAnswerSubmitted) {
      setSelectedOption(index);
    }
  };

  const handleSubmitAnswer = () => {
    if (selectedOption !== null) {
      const isCorrect = selectedOption === shuffledQuestions[currentQuestionIndex].answer;
      if (isCorrect) {
        setScore((prevScore) => prevScore + 1);
      }
      setIsAnswerSubmitted(true);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < shuffledQuestions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedOption(null);
      setIsAnswerSubmitted(false);
    } else {
      setIsQuizFinished(true);
    }
  };

  const handlePlayAgain = () => {
    window.location.reload(); // Recarrega a página para reiniciar o quiz
  };

  const handleGoBack = () => {
    window.location.href = "/quiz"; // Redireciona para a rota "/quiz"
  };

  return (
    <div>
      <div className="header">
        <Header activePage="quiz" />
      </div>

      <div className="quiz-container">
        {!isQuizFinished ? (
          shuffledQuestions.length > 0 && (
            <>
              <h2>{shuffledQuestions[currentQuestionIndex].question}</h2>
              <div className="options">
                {shuffledQuestions[currentQuestionIndex].options.map(
                  (option: string, index: number) => (
                    <button
                      key={index}
                      className={`option-button ${
                        selectedOption === index ? "selected" : ""
                      } ${
                        isAnswerSubmitted
                          ? index === shuffledQuestions[currentQuestionIndex].answer
                            ? "correct"
                            : index === selectedOption
                            ? "incorrect"
                            : ""
                          : ""
                      }`}
                      onClick={() => handleOptionSelect(index)}
                      disabled={isAnswerSubmitted}
                    >
                      {option}
                    </button>
                  )
                )}
              </div>
              {isAnswerSubmitted ? (
                <button className="next-button" onClick={handleNextQuestion}>
                  Próxima
                </button>
              ) : (
                <button
                  className="submit-button"
                  onClick={handleSubmitAnswer}
                  disabled={selectedOption === null}
                >
                  Confirmar
                </button>
              )}
            </>
          )
        ) : (
          <div className="quiz-modal">
            <div className="quiz-modal-content">
              <h2>Quiz Concluído!</h2>
              <p>Sua pontuação: {((score / shuffledQuestions.length) * 100).toFixed(2)}%</p>
              <div className="modal-buttons">
                <button onClick={handlePlayAgain} className="modal-button">
                  Jogar Novamente
                </button>
                <button onClick={handleGoBack} className="modal-button">
                  Voltar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HarryPotterAzkabanQuiz;
