import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Forca from './Pages/Forca';
import Home from './Pages/Home';
import Noticias from './Pages/Noticias';
import PegueOPomo from './Pages/PegueOPomo';
import Potterdle from './Pages/Potterdle';
import QuizPage from './Pages/QuizPage';
import Quiz1 from './Pages/Quizes/PedraFilosofal';
import Quiz2 from './Pages/Quizes/CamaraSecreta';
import Quiz3 from './Pages/Quizes/PrisioneiroAzkaban';
import Quiz4 from './Pages/Quizes/CaliceFogo';
import Quiz5 from './Pages/Quizes/OrdemDaFenix';
import Quiz6 from './Pages/Quizes/EnigmaPrincipe';
import Quiz7 from './Pages/Quizes/Reliquias';
import Noticia1 from './Pages/News/Noticia1';
import Noticia2 from './Pages/News/Noticia2';
import Noticia3 from './Pages/News/Noticia3';
import Noticia4 from './Pages/News/Noticia4';

const App: React.FC = () => {
  return (
    <>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-430349919669342"
          crossOrigin="anonymous"
        ></script>
      </Helmet>

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/potterdle" element={<Potterdle />} />
          <Route path="/quiz" element={<QuizPage />} />
          <Route path="/pegue-o-pomo" element={<PegueOPomo />} />
          <Route path="/forca" element={<Forca />} />
          <Route path="/noticias" element={<Noticias />} />
          <Route path="/quiz/1" element={<Quiz1 />} />
          <Route path="/quiz/2" element={<Quiz2 />} />
          <Route path="/quiz/3" element={<Quiz3 />} />
          <Route path="/quiz/4" element={<Quiz4 />} />
          <Route path="/quiz/5" element={<Quiz5 />} />
          <Route path="/quiz/6" element={<Quiz6 />} />
          <Route path="/quiz/7" element={<Quiz7 />} />
          <Route path="/noticia/1" element={<Noticia1 />} />
          <Route path="/noticia/2" element={<Noticia2 />} />
          <Route path="/noticia/3" element={<Noticia3 />} />
          <Route path="/noticia/4" element={<Noticia4 />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
