import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

interface HeaderProps {
  activePage: string;
}

const Header: React.FC<HeaderProps> = ({ activePage }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const getActivePageName = () => {
    switch(activePage) {
      case 'home':
        return 'Início';
      case 'potterdle':
        return 'Potterdle';
      case 'quiz':
        return 'Quiz';
      case 'pegue-o-pomo':
        return 'Pegue o Pomo';
      case 'forca':
        return 'Forca';
      case 'noticias':
        return 'Notícias';
      default:
        return '';
    }
  };

  return (
    <header className={`modern-header ${menuOpen ? 'open' : ''}`}>
      <div className="menu-icon" onClick={toggleMenu}>
        ☰ {getActivePageName()}
      </div>
      <nav className="nav-links">
        <Link to="/" className={activePage === 'home' ? 'active' : ''}>Início</Link>
        <Link to="/potterdle" className={activePage === 'potterdle' ? 'active' : ''}>Potterdle</Link>
        <Link to="/quiz" className={activePage === 'quiz' ? 'active' : ''}>Quiz</Link>
        <Link to="/pegue-o-pomo" className={activePage === 'pegue-o-pomo' ? 'active' : ''}>Pegue o Pomo</Link>
        <Link to="/forca" className={activePage === 'forca' ? 'active' : ''}>Forca</Link>
        <Link to="/noticias" className={activePage === 'noticias' ? 'active' : ''}>Notícias</Link>
      </nav>
    </header>
  );
};

export default Header;
