import React, { useEffect, useRef } from "react";
import Header from "../Components/Header/Header";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../CSS/Global.css";
import "../CSS/Home.css";

import gryffindorImg from "../Assets/Hogwarts/grifinoria.webp";
import slytherinImg from "../Assets/Hogwarts/sonserina.webp";
import hufflepuffImg from "../Assets/Hogwarts/lufa-lufa.webp";
import ravenclawImg from "../Assets/Hogwarts/corvinal.webp";

gsap.registerPlugin(ScrollTrigger);

const Home: React.FC = () => {
  const homeSectionRef = useRef<HTMLDivElement>(null);
  const gryffindorSectionRef = useRef<HTMLDivElement>(null);
  const slytherinSectionRef = useRef<HTMLDivElement>(null);
  const hufflepuffSectionRef = useRef<HTMLDivElement>(null);
  const ravenclawSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sections = [
      { ref: homeSectionRef, direction: "center" },
      { ref: gryffindorSectionRef, direction: "center" },
      { ref: slytherinSectionRef, direction: "center" },
      { ref: hufflepuffSectionRef, direction: "center" },
      { ref: ravenclawSectionRef, direction: "center" },
    ];

    sections.forEach((section, index) => {
      const sectionElement = section.ref.current;
      if (sectionElement) {
        gsap.fromTo(
          sectionElement,
          { opacity: index === 0 ? 1 : 0, y: index === 0 ? 0 : 100 },
          {
            opacity: 1,
            y: 0,
            duration: 1.5,
            ease: "power4.out",
            scrollTrigger: {
              trigger: sectionElement,
              start: index === 0 ? "top top" : "top 80%",
              end: "bottom top",
              scrub: true,
            },
          }
        );

        gsap.fromTo(
          sectionElement.querySelectorAll(".house-image"),
          { scale: 0.8, opacity: 0 },
          {
            scale: 1,
            opacity: 1,
            duration: 1.5,
            ease: "elastic.out(1, 0.75)",
            scrollTrigger: {
              trigger: sectionElement,
              start: "top 90%",
              end: "bottom 10%",
              scrub: true,
            },
          }
        );

        gsap.fromTo(
          sectionElement.querySelectorAll(".house-content"),
          { y: 50, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            duration: 1.5,
            ease: "power4.out",
            scrollTrigger: {
              trigger: sectionElement,
              start: "top 80%",
              end: "bottom 20%",
              scrub: true,
            },
          }
        );
      }
    });
  }, []);

  return (
    <div className="home-container">
      <div className="header">
        <Header activePage="home" />
      </div>

      <section className="section section-home" ref={homeSectionRef}>
        <div className="section-content">
          <div className="title">
            <h1>Potter Games</h1>
          </div>

          <div className="welcome">
            <p>
              Bem-vindo ao Potter Games! Este site reúne vários jogos
              relacionados ao mundo de Harry Potter.
            </p>
            <ul className="game-list">
              <li className="game-item">
                Potterdle: Adivinhe a palavra secreta de Harry Potter em 5
                tentativas (jogo estilo "termo").
              </li>
              <li className="game-item">
                Jogo da Forca: Tente descobrir a palavra antes de ser enforcado.
              </li>
              <li className="game-item">
                Quizzes: Teste seus conhecimentos sobre o mundo de Harry Potter.
              </li>
              <li className="game-item">
                Pegue o Pomo: Capture o pomo de ouro.
              </li>
              <li className="game-item">
                Notícias: Fique por dentro das últimas novidades do mundo de
                Harry Potter.
              </li>
            </ul>
          </div>

          <div className="creator">
            <p>
              Criador do site: Otávio Cunha, criador de conteúdo no TikTok e
              Instagram com mais de 135 mil seguidores. Sou programador de
              software formado em Engenharia de Software pela PUCRS.
            </p>
          </div>
        </div>
      </section>

      <section
        className="section section-gryffindor"
        ref={gryffindorSectionRef}
      >
        <div className="section-content">
          <div className="gryffindor-content">
            <h2>Grifinória</h2>
            <div className="house-content">
              <p>"Quem sabe sua morada é a Grifinória casa onde habitam os corações indômitos. Ousadia e sangue-frio e nobreza destacam os alunos da Grifinória dos demais."</p>
              <table className="house-table glass-table">
                <tr>
                  <td>Fundador</td>
                  <td>Godric Griffyndor</td>
                </tr>
                <tr>
                  <td>Mascote</td>
                  <td>Leão</td>
                </tr>
                <tr>
                  <td>Fantasma</td>
                  <td>Nick Quase Sem Cabeça</td>
                </tr>
                <tr>
                  <td>Elemento</td>
                  <td>Fogo</td>
                </tr>
                <tr>
                  <td>Cores</td>
                  <td>Escarlate e Dourado</td>
                </tr>
                <tr>
                  <td>Traços</td>
                  <td>Coragem, ousadia, bravura e cavalheirismo. Seus membros são considerados nobres, às vezes ao ponto da imprudência.</td>
                </tr>
              </table>
            </div>
            <img src={gryffindorImg} alt="Grifinória" className="house-image" />
          </div>
        </div>
      </section>

      <section className="section section-slytherin" ref={slytherinSectionRef}>
        <div className="section-content">
          <div className="slytherin-content">
            <h2>Sonserina</h2>
            <div className="house-content">
              <p>"Quem sabe a Sonserina será a sua casa e ali fará seus verdadeiros amigos, homens de astúcia que usam quaisquer meios para atingir os fins que antes colimaram."</p>
              <table className="house-table glass-table">
                <tr>
                  <td>Fundador</td>
                  <td>Salazar Slytherin</td>
                </tr>
                <tr>
                  <td>Mascote</td>
                  <td>Serpente</td>
                </tr>
                <tr>
                  <td>Fantasma</td>
                  <td>Barão Sangrento</td>
                </tr>
                <tr>
                  <td>Elemento</td>
                  <td>Água</td>
                </tr>
                <tr>
                  <td>Cores</td>
                  <td>Verde e Prata</td>
                </tr>
                <tr>
                  <td>Traços</td>
                  <td>Astúcia, desenvoltura, orgulho, criatividade, liderança, determinação e ambição.</td>
                </tr>
              </table>
            </div>
            <img src={slytherinImg} alt="Sonserina" className="house-image" />
          </div>
        </div>
      </section>

      <section className="section section-hufflepuff" ref={hufflepuffSectionRef}>
        <div className="section-content">
          <div className="hufflepuff-content">
            <h2>Lufa-Lufa</h2>
            <div className="house-content">
              <p>"Quem sabe é na Lufa-Lufa que você vai morar, onde seus moradores são justos e leais, pacientes, sinceros, sem medo da dor."</p>
              <table className="house-table glass-table">
                <tr>
                  <td>Fundador</td>
                  <td>Helga Lufa-Lufa</td>
                </tr>
                <tr>
                  <td>Mascote</td>
                  <td>Texugo</td>
                </tr>
                <tr>
                  <td>Fantasma</td>
                  <td>Frei Gorducho</td>
                </tr>
                <tr>
                  <td>Elemento</td>
                  <td>Terra</td>
                </tr>
                <tr>
                  <td>Cores</td>
                  <td>Amarelo e Preto</td>
                </tr>
                <tr>
                  <td>Traços</td>
                  <td>Justiça, lealdade, paciência, sinceridade e modéstia.</td>
                </tr>
              </table>
            </div>
            <img src={hufflepuffImg} alt="Lufa-Lufa" className="house-image" />
          </div>
        </div>
      </section>

      <section className="section section-ravenclaw" ref={ravenclawSectionRef}>
        <div className="section-content">
          <div className="ravenclaw-content">
            <h2>Corvinal</h2>
            <div className="house-content">
              <p>"Quem sabe seja a velha e sábia Corvinal, a casa dos que têm a mente sempre alerta, onde os homens de grande espírito e saber sempre encontrarão companheiros seus iguais."</p>
              <table className="house-table glass-table">
                <tr>
                  <td>Fundador</td>
                  <td>Rowena Ravenclaw</td>
                </tr>
                <tr>
                  <td>Mascote</td>
                  <td>Águia</td>
                </tr>
                <tr>
                  <td>Fantasma</td>
                  <td>Dama Cinzenta</td>
                </tr>
                <tr>
                  <td>Elemento</td>
                  <td>Ar</td>
                </tr>
                <tr>
                  <td>Cores</td>
                  <td>Azul e Bronze</td>
                </tr>
                <tr>
                  <td>Traços</td>
                  <td>Sagacidade, aprendizagem, sabedoria, criatividade, aceitação e inteligência.</td>
                </tr>
              </table>
            </div>
            <img src={ravenclawImg} alt="Corvinal" className="house-image" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
