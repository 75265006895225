import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import "../../CSS/Global.css";
import "../../CSS/Quiz.css";

const harryPotterQuestions = [
    {
      question: "Quem é o fundador da Ordem da Fênix?",
      options: ["Alvo Dumbledore", "Sirius Black", "Remo Lupin", "Minerva McGonagall"],
      answer: 0,
    },
    {
      question: "Qual é o nome do professor de Defesa Contra as Artes das Trevas em 'A Ordem da Fênix'?",
      options: ["Dolores Umbridge", "Severo Snape", "Remo Lupin", "Gilderoy Lockhart"],
      answer: 0,
    },
    {
      question: "Onde a Ordem da Fênix tem sua sede?",
      options: ["Grimmauld Place", "Hogwarts", "A Toca", "Ministério da Magia"],
      answer: 0,
    },
    {
      question: "Quem é nomeado o novo Alto Inquisidor de Hogwarts?",
      options: ["Dolores Umbridge", "Cornelius Fudge", "Severo Snape", "Minerva McGonagall"],
      answer: 0,
    },
    {
      question: "Qual é a profecia que Voldemort deseja encontrar?",
      options: [
        "Aquela que envolve Harry Potter e ele mesmo",
        "Sobre o retorno dos Comensais da Morte",
        "A destruição de Hogwarts",
        "A imortalidade de Voldemort",
      ],
      answer: 0,
    },
    {
      question: "Quem ensina Harry a usar a Oclumência?",
      options: ["Severo Snape", "Alvo Dumbledore", "Sirius Black", "Remo Lupin"],
      answer: 0,
    },
    {
      question: "Qual é o nome da organização secreta formada por Harry para ensinar defesa aos alunos?",
      options: ["Armada de Dumbledore", "Clube do Slugue", "Comensais da Morte", "Tropa Lupin"],
      answer: 0,
    },
    {
      question: "Quem é atacado por uma cobra enquanto está em serviço no Ministério da Magia?",
      options: ["Arthur Weasley", "Severo Snape", "Sirius Black", "Lucius Malfoy"],
      answer: 0,
    },
    {
      question: "Qual é o nome da criatura que ataca Harry no início do livro?",
      options: ["Dementador", "Hipogrifo", "Thestral", "Acromântula"],
      answer: 0,
    },
    {
      question: "Quem foi o líder original da Armada de Dumbledore?",
      options: ["Harry Potter", "Hermione Granger", "Rony Weasley", "Neville Longbottom"],
      answer: 0,
    },
    {
      question: "Qual é o feitiço usado para convocar os membros da Armada de Dumbledore?",
      options: ["Patronus", "Expecto Patronum", "Serpensortia", "Protego"],
      answer: 0,
    },
    {
      question: "Quem mata Sirius Black?",
      options: ["Bellatrix Lestrange", "Severo Snape", "Lucius Malfoy", "Draco Malfoy"],
      answer: 0,
    },
    {
      question: "Onde Sirius Black é morto?",
      options: ["No Ministério da Magia", "Em Hogwarts", "Em Grimmauld Place", "Na Floresta Proibida"],
      answer: 0,
    },
    {
      question: "Qual é a cor da pena usada por Umbridge para punir os alunos?",
      options: ["Vermelha", "Preta", "Dourada", "Prateada"],
      answer: 1,
    },
    {
      question: "Quem se torna o novo Ministro da Magia após a queda de Cornelius Fudge?",
      options: ["Rufus Scrimgeour", "Alvo Dumbledore", "Severo Snape", "Arthur Weasley"],
      answer: 0,
    },
  ];
  

const HarryPotterPhoenixQuiz: React.FC = () => {
  const [shuffledQuestions, setShuffledQuestions] = useState<any[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [score, setScore] = useState(0);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [isQuizFinished, setIsQuizFinished] = useState(false);

  function shuffleOptions(options: string[]): string[] {
    const shuffledOptions = [...options];
    for (let i = shuffledOptions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledOptions[i], shuffledOptions[j]] = [shuffledOptions[j], shuffledOptions[i]];
    }
    return shuffledOptions;
  }

  useEffect(() => {
    const shuffled = harryPotterQuestions.map((question) => {
      const options = shuffleOptions(question.options);
      const correctAnswerIndex = options.indexOf(question.options[question.answer]);
      return {
        ...question,
        options,
        answer: correctAnswerIndex,
      };
    });
    setShuffledQuestions(shuffled);
  }, []);

  const handleOptionSelect = (index: number) => {
    if (!isAnswerSubmitted) {
      setSelectedOption(index);
    }
  };

  const handleSubmitAnswer = () => {
    if (selectedOption !== null) {
      const isCorrect = selectedOption === shuffledQuestions[currentQuestionIndex].answer;
      if (isCorrect) {
        setScore((prevScore) => prevScore + 1);
      }
      setIsAnswerSubmitted(true);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < shuffledQuestions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedOption(null);
      setIsAnswerSubmitted(false);
    } else {
      setIsQuizFinished(true);
    }
  };

  const handlePlayAgain = () => {
    window.location.reload(); // Recarrega a página para reiniciar o quiz
  };

  const handleGoBack = () => {
    window.location.href = "/quiz"; // Redireciona para a rota "/quiz"
  };

  return (
    <div>
      <div className="header">
        <Header activePage="quiz" />
      </div>

      <div className="quiz-container">
        {!isQuizFinished ? (
          shuffledQuestions.length > 0 && (
            <>
              <h2>{shuffledQuestions[currentQuestionIndex].question}</h2>
              <div className="options">
                {shuffledQuestions[currentQuestionIndex].options.map(
                  (option: string, index: number) => (
                    <button
                      key={index}
                      className={`option-button ${
                        selectedOption === index ? "selected" : ""
                      } ${
                        isAnswerSubmitted
                          ? index === shuffledQuestions[currentQuestionIndex].answer
                            ? "correct"
                            : index === selectedOption
                            ? "incorrect"
                            : ""
                          : ""
                      }`}
                      onClick={() => handleOptionSelect(index)}
                      disabled={isAnswerSubmitted}
                    >
                      {option}
                    </button>
                  )
                )}
              </div>
              {isAnswerSubmitted ? (
                <button className="next-button" onClick={handleNextQuestion}>
                  Próxima
                </button>
              ) : (
                <button
                  className="submit-button"
                  onClick={handleSubmitAnswer}
                  disabled={selectedOption === null}
                >
                  Confirmar
                </button>
              )}
            </>
          )
        ) : (
          <div className="quiz-modal">
            <div className="quiz-modal-content">
              <h2>Quiz Concluído!</h2>
              <p>Sua pontuação: {((score / shuffledQuestions.length) * 100).toFixed(2)}%</p>
              <div className="modal-buttons">
                <button onClick={handlePlayAgain} className="modal-button">
                  Jogar Novamente
                </button>
                <button onClick={handleGoBack} className="modal-button">
                  Voltar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HarryPotterPhoenixQuiz;
