import React from 'react';
import './tecladoTermo.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackspace } from '@fortawesome/free-solid-svg-icons';

interface TecladoTermoProps {
  onKeyPress: (key: string) => void;
  usedKeys: { [key: string]: string[] }; // Agora é um array de strings para cada modo
  mode: string; // Adicione o modo como uma prop
}

const keys = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['z', 'x', 'c', 'v', 'b', 'n', 'm', 'enter', 'backspace'],
];

const getKeyStyles = (key: string, mode: string, usedKeys: { [key: string]: string[] }) => {
  const numBoards = mode === 'normal' ? 1 : mode === 'duplas' ? 2 : mode === 'trios' ? 3 : mode === 'quarteto' ? 4 : 5;
  const styles = [];

  for (let i = 0; i < numBoards; i++) {
    styles.push(usedKeys[key] && usedKeys[key][i] ? usedKeys[key][i] : '');
  }

  return styles;
};

const TecladoTermo: React.FC<TecladoTermoProps> = ({ onKeyPress, usedKeys, mode }) => {
  return (
    <div className="teclado-termo">
      {keys.map((row, rowIndex) => (
        <div key={rowIndex} className="key-row">
          {row.map((key) =>
            key === 'backspace' ? (
              <button
                key={key}
                className="key large-key"
                onClick={() => onKeyPress('Backspace')}
              >
                <FontAwesomeIcon icon={faBackspace} className="icon-backspace" />
              </button>
            ) : key === 'enter' ? (
              <button
                key={key}
                className="key large-key"
                onClick={() => onKeyPress('Enter')}
              >
                Enter
              </button>
            ) : (
              <button
                key={key}
                className={`key`}
                onClick={() => onKeyPress(key)}
              >
                <div className="key-label">{key.toUpperCase()}</div>
                <div className="key-parts">
                  {getKeyStyles(key, mode, usedKeys).map((colorClass, idx) => (
                    <div key={idx} className={`key-part key-part-${idx} ${colorClass}`} />
                  ))}
                </div>
              </button>
            )
          )}
        </div>
      ))}
    </div>
  );
};

export default TecladoTermo;
