import React, { useEffect } from "react";
import Header from "../../Components/Header/Header";
import NEWS3 from "../../Assets/ImagensNews/News3/prove-que-sabe-tudo-sobre-gina-weasley-harry-potter.webp";
import { FastAverageColor } from 'fast-average-color';
import "../../CSS/Global.css";
import "../../CSS/NoticiaDetalhada.css";

import IMG1 from "../../Assets/ImagensNews/News3/gina_wesley_-_reproducao.webp";

const Noticia3: React.FC = () => {

  useEffect(() => {
    const images = document.querySelectorAll('.noticia-detalhada-image');

    images.forEach((image) => {
      (image as HTMLElement).style.transition = 'transform 0.2s ease, box-shadow 0.4s ease';

      const handleMouseMove = (e: MouseEvent) => {
        const fac = new FastAverageColor();
        const color = fac.getColor(image as HTMLImageElement);
        const rgbColor = `rgba(${color.value[0] * 2}, ${color.value[1] * 2}, ${color.value[2] * 2}, 0.75)`;
        const rect = image.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const centerX = rect.width / 2;
        const centerY = rect.height / 2;

        const rotateX = ((y - centerY) / centerY) * -15;
        const rotateY = ((x - centerX) / centerX) * 15;

        const shadowX = ((x - centerX) / centerX) * 20;
        const shadowY = ((y - centerY) / centerY) * 20;

        (image as HTMLElement).style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
        (image as HTMLElement).style.boxShadow = `${shadowX}px ${shadowY}px 25px ${rgbColor}`;
      };

      const handleMouseLeave = () => {
        (image as HTMLElement).style.transform = 'perspective(1000px) rotateX(0) rotateY(0)';
        (image as HTMLElement).style.boxShadow = 'none';
      };

      image.addEventListener('mousemove', handleMouseMove as EventListener);
      image.addEventListener('mouseleave', handleMouseLeave as EventListener);

      return () => {
        image.removeEventListener('mousemove', handleMouseMove as EventListener);
        image.removeEventListener('mouseleave', handleMouseLeave as EventListener);
      };
    });
  }, []);

  return (
    <div className="noticia-detalhada-page">
      <Header activePage="noticias" />
      <div className="noticia-detalhada-content">
        <h1>Harry Potter: Bonnie Wright Deseja Desenvolvimento Maior para Gina Weasley na Nova Série da HBO</h1>
        <p className="noticia-detalhada-date">1 de Setembro de 2024</p>
        <img
          src={NEWS3}
          alt="Bonnie Wright deseja maior desenvolvimento para Gina Weasley"
          className="noticia-detalhada-image"
        />
        <div className="noticia-detalhada-body">
          <p>
            A atriz **Bonnie Wright**, conhecida por seu papel como **Gina Weasley** nos filmes de *Harry Potter*, expressou seu desejo de ver a personagem mais desenvolvida na nova série da **HBO**. Em uma entrevista à **Variety** no último domingo (1º), a atriz comentou que está contente em passar o papel para uma nova intérprete, mas espera que alguns aspectos da personagem, que ficaram de fora dos filmes, sejam melhor explorados na série.
          </p>
          <p>
            *"Espero ver mais do desenvolvimento da relação entre Gina e Harry [Potter]. Há momentos sutis nos livros em que eles começam a se apaixonar. Penso mais no arco da Gina como uma parceira leal para o Harry, alguém que realmente o entende e conhece sua história. Ela é, por isso, a parceira ideal para ele."* afirmou Wright durante a entrevista.
          </p>

          <img
            src={IMG1}
            alt="Gina Weasley em Ordem da Fênix"
            className="noticia-detalhada-image"
          />

          <p>
            Wright também mencionou outros personagens que espera ver mais em destaque na série: *"Há muitos personagens dos livros que eu adoraria ter visto mais, como Neville e Luna. Como fã, eu realmente espero que esses momentos sejam incluídos nos episódios da série."*
          </p>
          <p>
            Quando perguntada sobre a possibilidade de retornar ao universo de **Harry Potter** em um novo papel, a atriz descartou a ideia: *"Acho que seria confuso e estranho. Estou feliz em apenas assistir."*
          </p>
          <p>
            A **Warner Bros.** ainda não divulgou muitos detalhes sobre a trama da série, que terá a participação da autora **J.K. Rowling** na produção. O projeto, que será lançado pela plataforma **Max**, ainda não tem data de estreia confirmada.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Noticia3;
