import React from "react";
import Header from "../Components/Header/Header";
import "../CSS/Global.css";
import "../CSS/QuizPage.css"; 
import QUIZ1 from "../Assets/ImagensQuiz/pedraFilosofal.webp"
import QUIZ2 from "../Assets/ImagensQuiz/camaraSecreta.webp"
import QUIZ3 from "../Assets/ImagensQuiz/prisioneiroAzkaban.webp"
import QUIZ4 from "../Assets/ImagensQuiz/caliceFogo.webp"
import QUIZ5 from "../Assets/ImagensQuiz/OrdemFenix.webp"
import QUIZ6 from "../Assets/ImagensQuiz/EnigmaPrincipe.webp"
import QUIZ7 from "../Assets/ImagensQuiz/Reliquias.webp"

const quizzes = [
  { id: 1, title: "Você conhece tudo sobre Harry Potter e a Pedra Filosofal?", image: QUIZ1 },
  { id: 2, title: "Você conhece tudo sobre Harry Potter e a Câmara Secreta?", image: QUIZ2 },
  { id: 3, title: "Você conhece tudo sobre Harry Potter e o Prisioneiro de Azkaban?", image: QUIZ3 },
  { id: 4, title: "Você conhece tudo sobre Harry Potter e o Cálice de Fogo?", image: QUIZ4 },
  { id: 5, title: "Você conhece tudo sobre Harry Potter e a Ordem da Fênix?", image: QUIZ5 },
  { id: 6, title: "Você conhece tudo sobre Harry Potter e o Enigma do Príncipe?", image: QUIZ6 },
  { id: 7, title: "Você conhece tudo sobre Harry Potter e as Relíquias da Morte?", image: QUIZ7 },

];

const QuizPage: React.FC = () => {
  const startQuiz = (id: number) => {
    window.location.href = `/quiz/${id}`; 
  };

  return (
    <div>
      <div className="header">
        <Header activePage="quiz" />
      </div>

      <div className="title">
        <h1>Escolha seu Quiz</h1>
      </div>

      <div className="quiz-cards">
        {quizzes.map((quiz) => (
          <div key={quiz.id} className="quiz-card" onClick={() => startQuiz(quiz.id)}>
            <img src={quiz.image} alt={quiz.title} />
            <div className="quiz-title">{quiz.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuizPage;
