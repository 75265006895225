import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import "../../CSS/Global.css";
import "../../CSS/Quiz.css";

const harryPotterQuestions = [
    {
      question: "O que são as Relíquias da Morte?",
      options: ["A Varinha das Varinhas, a Pedra da Ressurreição e a Capa da Invisibilidade", "Os Horcruxes", "As Horcruxes de Voldemort", "Os artefatos de Hogwarts"],
      answer: 0,
    },
    {
      question: "Quem destrói a Horcrux dentro do medalhão de Salazar Sonserina?",
      options: ["Rony Weasley", "Hermione Granger", "Harry Potter", "Neville Longbottom"],
      answer: 0,
    },
    {
      question: "Qual personagem é torturado por Bellatrix Lestrange na Mansão Malfoy?",
      options: ["Hermione Granger", "Luna Lovegood", "Rony Weasley", "Harry Potter"],
      answer: 0,
    },
    {
      question: "Quem entrega a espada de Gryffindor a Harry no lago congelado?",
      options: ["Rony Weasley", "Severo Snape", "Dobby", "Aberforth Dumbledore"],
      answer: 0,
    },
    {
      question: "Qual é o nome do elfo doméstico que ajuda Harry e seus amigos a escapar da Mansão Malfoy?",
      options: ["Dobby", "Kreacher", "Winky", "Monstro"],
      answer: 0,
    },
    {
      question: "Quem mata Dobby na Mansão Malfoy?",
      options: ["Bellatrix Lestrange", "Severo Snape", "Draco Malfoy", "Lucius Malfoy"],
      answer: 0,
    },
    {
      question: "Qual Horcrux está escondido no cofre de Bellatrix Lestrange?",
      options: ["Taça de Helga Lufa-Lufa", "Diadema de Rowena Ravenclaw", "Anel de Marvolo Gaunt", "Medalhão de Salazar Sonserina"],
      answer: 0,
    },
    {
      question: "Quem destrói a Taça de Helga Lufa-Lufa?",
      options: ["Hermione Granger", "Harry Potter", "Rony Weasley", "Neville Longbottom"],
      answer: 0,
    },
    {
      question: "Quem encontra a Diadema de Rowena Ravenclaw na Sala Precisa?",
      options: ["Harry Potter", "Hermione Granger", "Draco Malfoy", "Luna Lovegood"],
      answer: 0,
    },
    {
      question: "Qual Horcrux é destruída por Neville Longbottom?",
      options: ["Nagini", "Diadema de Rowena Ravenclaw", "Medalhão de Salazar Sonserina", "Taça de Helga Lufa-Lufa"],
      answer: 0,
    },
    {
      question: "Quem mata Severo Snape?",
      options: ["Nagini", "Voldemort", "Bellatrix Lestrange", "Harry Potter"],
      answer: 0,
    },
    {
      question: "Quem fica com a Varinha das Varinhas no final da batalha?",
      options: ["Harry Potter", "Draco Malfoy", "Voldemort", "Neville Longbottom"],
      answer: 0,
    },
    {
      question: "Quem revela a verdadeira história das Relíquias da Morte?",
      options: ["Xenofílio Lovegood", "Alvo Dumbledore", "Severo Snape", "Aberforth Dumbledore"],
      answer: 0,
    },
    {
      question: "Qual Horcrux é destruída acidentalmente por Voldemort?",
      options: ["Harry Potter", "Anel de Marvolo Gaunt", "Diadema de Rowena Ravenclaw", "Nagini"],
      answer: 0,
    },
    {
      question: "Quem lidera a resistência em Hogwarts durante a ausência de Harry?",
      options: ["Neville Longbottom", "Ginny Weasley", "Luna Lovegood", "Rony Weasley"],
      answer: 0,
    },
  ];
  

const HarryPotterHallowsQuiz: React.FC = () => {
  const [shuffledQuestions, setShuffledQuestions] = useState<any[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [score, setScore] = useState(0);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [isQuizFinished, setIsQuizFinished] = useState(false);

  function shuffleOptions(options: string[]): string[] {
    const shuffledOptions = [...options];
    for (let i = shuffledOptions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledOptions[i], shuffledOptions[j]] = [shuffledOptions[j], shuffledOptions[i]];
    }
    return shuffledOptions;
  }

  useEffect(() => {
    const shuffled = harryPotterQuestions.map((question) => {
      const options = shuffleOptions(question.options);
      const correctAnswerIndex = options.indexOf(question.options[question.answer]);
      return {
        ...question,
        options,
        answer: correctAnswerIndex,
      };
    });
    setShuffledQuestions(shuffled);
  }, []);

  const handleOptionSelect = (index: number) => {
    if (!isAnswerSubmitted) {
      setSelectedOption(index);
    }
  };

  const handleSubmitAnswer = () => {
    if (selectedOption !== null) {
      const isCorrect = selectedOption === shuffledQuestions[currentQuestionIndex].answer;
      if (isCorrect) {
        setScore((prevScore) => prevScore + 1);
      }
      setIsAnswerSubmitted(true);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < shuffledQuestions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedOption(null);
      setIsAnswerSubmitted(false);
    } else {
      setIsQuizFinished(true);
    }
  };

  const handlePlayAgain = () => {
    window.location.reload(); // Recarrega a página para reiniciar o quiz
  };

  const handleGoBack = () => {
    window.location.href = "/quiz"; // Redireciona para a rota "/quiz"
  };

  return (
    <div>
      <div className="header">
        <Header activePage="quiz" />
      </div>

      <div className="quiz-container">
        {!isQuizFinished ? (
          shuffledQuestions.length > 0 && (
            <>
              <h2>{shuffledQuestions[currentQuestionIndex].question}</h2>
              <div className="options">
                {shuffledQuestions[currentQuestionIndex].options.map(
                  (option: string, index: number) => (
                    <button
                      key={index}
                      className={`option-button ${
                        selectedOption === index ? "selected" : ""
                      } ${
                        isAnswerSubmitted
                          ? index === shuffledQuestions[currentQuestionIndex].answer
                            ? "correct"
                            : index === selectedOption
                            ? "incorrect"
                            : ""
                          : ""
                      }`}
                      onClick={() => handleOptionSelect(index)}
                      disabled={isAnswerSubmitted}
                    >
                      {option}
                    </button>
                  )
                )}
              </div>
              {isAnswerSubmitted ? (
                <button className="next-button" onClick={handleNextQuestion}>
                  Próxima
                </button>
              ) : (
                <button
                  className="submit-button"
                  onClick={handleSubmitAnswer}
                  disabled={selectedOption === null}
                >
                  Confirmar
                </button>
              )}
            </>
          )
        ) : (
          <div className="quiz-modal">
            <div className="quiz-modal-content">
              <h2>Quiz Concluído!</h2>
              <p>Sua pontuação: {((score / shuffledQuestions.length) * 100).toFixed(2)}%</p>
              <div className="modal-buttons">
                <button onClick={handlePlayAgain} className="modal-button">
                  Jogar Novamente
                </button>
                <button onClick={handleGoBack} className="modal-button">
                  Voltar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HarryPotterHallowsQuiz;
