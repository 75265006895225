import React, { useEffect } from "react";
import Header from "../../Components/Header/Header";
import NEWS2 from "../../Assets/ImagensNews/News2/hpquem.webp";
import { FastAverageColor } from 'fast-average-color';
import "../../CSS/Global.css";
import "../../CSS/NoticiaDetalhada.css";

import IMG2 from "../../Assets/ImagensNews/News2/EAM_QDB_14_24_HARRY_POTTER_3a_4a_CAPAS_1-ptif.webp";
import IMG3 from "../../Assets/ImagensNews/News2/00A1773.webp";

const Noticia2: React.FC = () => {

  useEffect(() => {
    const images = document.querySelectorAll('.noticia-detalhada-image');

    images.forEach((image) => {
      (image as HTMLElement).style.transition = 'transform 0.2s ease, box-shadow 0.4s ease';

      const handleMouseMove = (e: MouseEvent) => {
        const fac = new FastAverageColor();
        const color = fac.getColor(image as HTMLImageElement);
        const rgbColor = `rgba(${color.value[0] * 2}, ${color.value[1] * 2}, ${color.value[2] * 2}, 0.75)`;
        const rect = image.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const centerX = rect.width / 2;
        const centerY = rect.height / 2;

        const rotateX = ((y - centerY) / centerY) * -15;
        const rotateY = ((x - centerX) / centerX) * 15;

        const shadowX = ((x - centerX) / centerX) * 20;
        const shadowY = ((y - centerY) / centerY) * 20;

        (image as HTMLElement).style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
        (image as HTMLElement).style.boxShadow = `${shadowX}px ${shadowY}px 25px ${rgbColor}`;
      };

      const handleMouseLeave = () => {
        (image as HTMLElement).style.transform = 'perspective(1000px) rotateX(0) rotateY(0)';
        (image as HTMLElement).style.boxShadow = 'none';
      };

      image.addEventListener('mousemove', handleMouseMove as EventListener);
      image.addEventListener('mouseleave', handleMouseLeave as EventListener);

      return () => {
        image.removeEventListener('mousemove', handleMouseMove as EventListener);
        image.removeEventListener('mouseleave', handleMouseLeave as EventListener);
      };
    });
  }, []);

  return (
    <div className="noticia-detalhada-page">
      <Header activePage="noticias" />
      <div className="noticia-detalhada-content">
        <h1>Quem Disse, Berenice? Lança Coleção de Maquiagem Inspirada em Harry Potter</h1>
        <p className="noticia-detalhada-date">2 de Setembro de 2024</p>
        <img
          src={NEWS2}
          alt="Quem Disse, Berenice? lança coleção de maquiagem inspirada em Harry Potter"
          className="noticia-detalhada-image"
        />
        <div className="noticia-detalhada-body">
          <p>
            A marca **Quem Disse, Berenice? (QDB)**, em colaboração com a **Warner Bros. Discovery Global Consumer Products (WBDGCP)**, lançou uma linha exclusiva de maquiagem inspirada no mundo mágico de **Harry Potter**. Esta coleção especial marca a maior colaboração já feita pela marca do Grupo Boticário e é a primeira coleção de maquiagem da franquia na América Latina.
          </p>
          <p>
            A nova linha de produtos, que abrange desde maquiagem até cuidados com a pele, é projetada para capitalizar a nostalgia da franquia Harry Potter e atrair novos consumidores. A QDB espera quadruplicar a receita gerada por colaborações anteriores, aproveitando a forte sinergia entre as marcas e a crescente demanda por produtos temáticos.
          </p>
          
          <h2>Produtos Inspirados em Hogwarts e no Mundo Bruxo</h2>
          <p>
            Um dos principais destaques da coleção é a **Paleta Multifuncional Bem-Vindo à Hogwarts**, que inclui 36 cores de sombra e seis produtos que podem ser usados como blush ou iluminador. A paleta, com embalagem em formato de livro, é inspirada nas quatro casas da Escola de Magia e Bruxaria.
          </p>
          <p>
            Outros produtos notáveis incluem os delineadores **Lumos** e **Incêndio**, disponíveis em prata e dourado, com esferas de glitter que criam um efeito tridimensional. O iluminador **O Pomo de Ouro**, com pérolas iridescentes, foi inspirado no esporte mágico **Quadribol**, e oferece um brilho mágico para qualquer maquiagem.
          </p>

          <img
            src={IMG2}
            alt="Delineadores Lumos e Incêndio"
            className="noticia-detalhada-image"
          />

          <h2>Balm Labial e Skincare Inspirados no Mundo Mágico</h2>
          <p>
            Além dos itens de maquiagem, a coleção também inclui três balms labiais inspirados nas sobremesas e guloseimas do universo de Harry Potter: **Bolo de Caldeirão** (tom avermelhado), **Sapo de Chocolate** (tom marrom chocolate) e **Sorvete de Limão** (incolor). Complementando a linha, a marca lança um kit de pincéis com design inspirado nas varinhas dos personagens e produtos de skincare, como gel de limpeza, água micelar e body splash.
          </p>

          <img
            src={IMG3}
            alt="Kit de pincéis inspirados nas varinhas de Harry Potter"
            className="noticia-detalhada-image"
          />

          <h2>Uma Collab Inovadora que Vai Além</h2>
          <p>
            Segundo **Marina Martins Vieira**, diretora de estratégia de marketing do Grupo Boticário, a parceria com a Warner Bros. Discovery foi projetada para criar uma experiência única para os consumidores, combinando elementos nostálgicos e colecionáveis. "Desenvolvemos a nova coleção pautada neste princípio, trazendo produtos altamente colecionáveis e originais que se conectam com a magia do universo Harry Potter."
          </p>
          <p>
            A linha de maquiagem já está disponível nos canais de venda da **Quem Disse, Berenice?**, incluindo lojas físicas e e-commerce. Essa colaboração reforça o sucesso da marca em criar produtos inovadores e cativantes para diferentes gerações de fãs do mundo mágico de Harry Potter.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Noticia2;
