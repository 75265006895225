import React, { useState, useEffect, useCallback } from "react";
import Header from "../Components/Header/Header";
import "../CSS/Global.css";
import "../CSS/Potterdle.css";
import TabelaTermo from "../Components/tabelaTermo/tabelaTermo";
import TecladoTermo from "../Components/tecladoTermo/tecladoTermo";
import CustomModal from "../Components/modal/customModal";
import { getRandomWord } from "../BD/palavras5Letras";
import Notification from "../Components/Notification/notification";

const Potterdle: React.FC = () => {

  const keys = [
    ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
    ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
    ['z', 'x', 'c', 'v', 'b', 'n', 'm', 'enter', 'backspace'],
  ];

  const [mode, setMode] = useState<string>("normal");
  const [guesses, setGuesses] = useState<string[][]>([]);
  const [currentGuesses, setCurrentGuesses] = useState<string[][]>([]);
  const [currentPositions, setCurrentPositions] = useState<number[]>([]);
  const [correctWords, setCorrectWords] = useState<string[]>([]);
  const [usedKeys, setUsedKeys] = useState<{ [key: string]: string[] }>({});
  const [modalState, setModalState] = useState<{ isOpen: boolean; message: string }>({ isOpen: false, message: "" });
  const [attempt, setAttempt] = useState<number>(0);
  const [lockedTables, setLockedTables] = useState<boolean[]>([]);

  // Estados para controlar a notificação
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationTimeout, setNotificationTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    handlePlayAgain(); // Reinicia o jogo sempre que o modo é alterado
  }, [mode]);

  const showErrorNotification = (message: string) => {
    setNotificationMessage(message);

    // Sempre fecha o alerta primeiro
    setShowNotification(false);

    // Limpa qualquer timeout anterior para garantir que seja reiniciado corretamente
    if (notificationTimeout) {
      clearTimeout(notificationTimeout);
    }

    // Força a exibição da nova notificação após um pequeno delay para "resetar" o estado
    setTimeout(() => {
      setShowNotification(true);

      // Define um novo timeout para esconder a notificação após 5 segundos
      const timeout = setTimeout(() => {
        setShowNotification(false);
      }, 5000);

      setNotificationTimeout(timeout);
    }, 100); // Pequeno delay para garantir que o estado de notificação seja reiniciado corretamente
  };

  const handleKeyPress = useCallback(
    (key: string) => {
      const newGuesses = guesses.map((g) => [...g]);
      const newCurrentGuesses = currentGuesses.map((cg) => [...cg]);
      const newCurrentPositions = [...currentPositions];
      const newLockedTables = [...lockedTables];

      let isWin = true;
      let validAttempt = false;

      newCurrentGuesses.forEach((guess, idx) => {
        if (newLockedTables[idx]) return;

        if (key === 'enter') {
          if (guess.join('').length === 5) {
            validAttempt = true;
            if (guess.join('') !== correctWords[idx]) {
              isWin = false;
            } else {
              newLockedTables[idx] = true;
            }
            newGuesses[idx] = [...newGuesses[idx], guess.join('')];
            newCurrentGuesses[idx] = Array(5).fill('');
            newCurrentPositions[idx] = 0;
            updateUsedKeys(guess.join(''), idx);
          } else {
            // Mostra a notificação de erro se a palavra tiver menos de 5 letras
            showErrorNotification("A palavra deve ter 5 letras.");
          }
        } else if (key === 'backspace' && newCurrentPositions[idx] > 0) {
          newCurrentGuesses[idx][newCurrentPositions[idx] - 1] = '';
          newCurrentPositions[idx] -= 1;
        } else if (/^[a-z]$/.test(key) && newCurrentPositions[idx] < 5) {
          newCurrentGuesses[idx][newCurrentPositions[idx]] = key;
          newCurrentPositions[idx] += 1;
        }
      });

      setGuesses(newGuesses);
      setCurrentGuesses(newCurrentGuesses);
      setCurrentPositions(newCurrentPositions);
      setLockedTables(newLockedTables);

      if (key === 'enter' && validAttempt) {
        const allGuessesFilled = newGuesses.every((g) => g.length > 0);
        if (allGuessesFilled && isWin) {
          setModalState({ isOpen: true, message: "Parabéns! Você acertou todas as palavras!" });
        } else if (attempt === 4) {
          setModalState({ isOpen: true, message: "Fim de Jogo! Você não acertou todas as palavras." });
        }
        setAttempt((prev) => prev + 1);
      }
    },
    [guesses, currentGuesses, currentPositions, correctWords, attempt, lockedTables]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();
      handleKeyPress(key);
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyPress]);

  const updateUsedKeys = (guess: string, index: number) => {
    const newUsedKeys: { [key: string]: string[] } = { ...usedKeys };

    guess.split('').forEach((letter, i) => {
      if (!newUsedKeys[letter]) newUsedKeys[letter] = Array(correctWords.length).fill('');

      if (correctWords[index][i] === letter) {
        newUsedKeys[letter][index] = 'correct';
      } else if (correctWords[index].includes(letter)) {
        newUsedKeys[letter][index] = 'present';
      } else {
        newUsedKeys[letter][index] = 'absent';
      }
    });

    setUsedKeys(newUsedKeys);
  };

  const handlePlayAgain = () => {
    const numOfBoards = mode === "normal" ? 1 : mode === "duplas" ? 2 : mode === "trios" ? 3 : mode === "quarteto" ? 4 : 5;
    setGuesses(Array(numOfBoards).fill([]));
    setCurrentGuesses(Array(numOfBoards).fill(Array(5).fill('')));
    setCorrectWords(Array(numOfBoards).fill(null).map(() => getRandomWord()));
    setCurrentPositions(Array(numOfBoards).fill(0));

    const initialUsedKeys: { [key: string]: string[] } = {};
    keys.flat().forEach((key) => {
      initialUsedKeys[key] = Array(numOfBoards).fill('');
    });

    setUsedKeys(initialUsedKeys);
    setAttempt(0);
    setLockedTables(Array(numOfBoards).fill(false));
    setModalState({ isOpen: false, message: "" });
  };

  const handleModeChange = (newMode: string) => {
    setMode(newMode);
  };

  const handleCellClick = (index: number) => {
    setCurrentPositions(Array(currentPositions.length).fill(index));
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  return (
    <div className={`App ${mode}`}>
      <Header activePage={"Potterdle"} />
      <h1 className="title">Termo - Modo {mode.charAt(0).toUpperCase() + mode.slice(1)}</h1>
      <div className="mode-selector">
        <button onClick={() => handleModeChange("normal")}>Normal</button>
        <button onClick={() => handleModeChange("duplas")}>Duplas</button>
        <button onClick={() => handleModeChange("trios")}>Trios</button>
        <button onClick={() => handleModeChange("quarteto")}>Quarteto</button>
        <button onClick={() => handleModeChange("quinteto")}>Quinteto</button>
      </div>
      <div className="tabuleiro-container">
        {guesses.map((_, index) => (
          <TabelaTermo
            key={index}
            guesses={guesses[index]}
            currentGuess={currentGuesses[index]}
            correctWord={correctWords[index]}
            onCellClick={handleCellClick}
            currentPosition={currentPositions[index]}
            isLocked={lockedTables[index]}
          />
        ))}
      </div>

      <div style={{ marginBottom: "3rem" }}>
        <TecladoTermo onKeyPress={handleKeyPress} usedKeys={usedKeys} mode={mode} />
      </div>

      <CustomModal
        isOpen={modalState.isOpen}
        message={modalState.message}
        correctWord={correctWords.join(", ")}
        onPlayAgain={handlePlayAgain}
      />

      {/* Exibe a notificação se showNotification for true */}
      {showNotification && (
        <Notification
          message={notificationMessage}
          type="error"
          onClose={handleCloseNotification}
        />
      )}
    </div>
  );
};

export default Potterdle;
