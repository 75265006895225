import React from 'react';
import './tabelaTermo.css';

interface TabelaTermoProps {
  guesses: string[];
  currentGuess: string[];
  correctWord: string;
  onCellClick: (index: number) => void;
  currentPosition: number;
  isLocked: boolean; // Nova prop para determinar se a tabela está bloqueada
}

const TabelaTermo: React.FC<TabelaTermoProps> = ({ guesses, currentGuess, correctWord, onCellClick, currentPosition, isLocked }) => {
  const getLetterClass = (letter: string, index: number, rowIndex: number) => {
    if (guesses[rowIndex]) {
      if (correctWord[index] === letter) return 'correct';
      if (correctWord.includes(letter)) return 'present';
      return 'absent';
    }
    return '';
  };

  return (
    <div className={`tabela-termo ${isLocked ? 'locked' : ''}`}>
      {Array.from({ length: 5 }).map((_, rowIndex) => (
        <div key={rowIndex} className="term-row">
          {Array.from({ length: 5 }).map((_, letterIndex) => (
            <div
              key={letterIndex}
              className={`term-cell ${
                guesses[rowIndex]
                  ? getLetterClass(guesses[rowIndex][letterIndex] || '', letterIndex, rowIndex)
                  : rowIndex === guesses.length && letterIndex === currentPosition
                  ? 'active'
                  : ''
              }`}
              onClick={() => !isLocked && rowIndex === guesses.length && onCellClick(letterIndex)}
            >
              {rowIndex === guesses.length
                ? currentGuess[letterIndex] || ''
                : guesses[rowIndex]
                ? guesses[rowIndex][letterIndex]
                : ''}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TabelaTermo;
