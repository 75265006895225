export const palavras: string[] = [
  "Draco",
  "Harry",
  "James",
  "Molly",
  "Fredy",
  "Lupin",
  "Ginny",
  "Ronny",
  "Dobby",
  "Padma",
  "Filch",
  "Fleur",
  "Barty",
  "Colin",
  "Snape",
  "Winky",
  "Cissa",
  "Hooch",
  "Rufus",
  "Yaxle",
  "Albus",
  "Argus",
  "Helga",
  "Katie",
  "Percy",
  "Remus",
  "Tonks",
  "Teddy",
  "Winky",
  "Yaxle",
  "Fudge",
  "Teddy",
  "Magic",
  "Auror",
  "Avada",
  "Barao",
  "Baron",
  "Barto",
  "Bruxa",
  "Bruxo",
  "Chang",
  "Felix",
  "Fenix",
  "Gnomo",
  "Goles",
  "Goyle",
  "Grifo",
  "Grope",
  "Guida",
];

export const getRandomWord = () => {
  const index = Math.floor(Math.random() * palavras.length);
  return palavras[index].toLowerCase();
};
