import React from 'react';
import './CustomModal.css';

interface CustomModalProps {
  isOpen: boolean;
  message: string;
  correctWord: string;
  onPlayAgain: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({ isOpen, message, correctWord, onPlayAgain }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{message}</h2>
        <p>A palavra correta era: <strong>{correctWord.toUpperCase()}</strong></p>
        <button onClick={onPlayAgain} className="play-again-button">Jogar Novamente</button>
      </div>
    </div>
  );
};

export default CustomModal;
