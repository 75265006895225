import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header/Header";
import "../CSS/Global.css";
import "../CSS/Noticias.css";

import NEWS1 from '../Assets/ImagensNews/News1/poster.webp'
import NEWS2 from '../Assets/ImagensNews/News2/hpquem.webp'
import NEWS3 from '../Assets/ImagensNews/News3/prove-que-sabe-tudo-sobre-gina-weasley-harry-potter.webp'
import NEWS4 from '../Assets/ImagensNews/ministryofmagic.webp'

const Noticias: React.FC = () => {
  const navigate = useNavigate();

  // Mapa dos meses em português para números
  const monthsMap: { [key: string]: number } = {
    "Janeiro": 0,
    "Fevereiro": 1,
    "Março": 2,
    "Abril": 3,
    "Maio": 4,
    "Junho": 5,
    "Julho": 6,
    "Agosto": 7,
    "Setembro": 8,
    "Outubro": 9,
    "Novembro": 10,
    "Dezembro": 11,
  };

  const newsItems = [
    {
      id: "1",
      title: "Harry Potter: Quidditch Champions Já Está Disponível para Consoles e PC!",
      date: "3 de Setembro de 2024",
      category: "Jogos de Vídeo Game",
      description: "A Warner Bros. Games e a Unbroken Studios acabam de anunciar o lançamento oficial de Harry Potter: Quidditch Champions",
      image: NEWS1
    },
    {
      id: "2",
      title: "Quem Disse, Berenice? Lança Coleção de Maquiagem Inspirada em Harry Potter",
      date: "2 de Setembro de 2024",
      category: "Novidade",
      description: "A marca Quem Disse, Berenice?, em colaboração com a Warner Bros. Discovery Global Consumer Products, lançou uma linha exclusiva de maquiagem inspirada no mundo mágico de Harry Potter.",
      image: NEWS2
    },
    {
      id: "3",
      title: "Harry Potter: Bonnie Wright Deseja Desenvolvimento Maior para Gina Weasley na Nova Série da HBO",
      date: "29 de Agosto de 2024",
      category: "Notícia",
      description: "A atriz Bonnie Wright, conhecida por seu papel como Gina Weasley nos filmes de Harry Potter, expressou seu desejo de ver a personagem mais desenvolvida na nova série da HBO.",
      image: NEWS3
    },
    {
      id: "4",
      title: "Universal Orlando Lança Nova Área Temática Inspirada em Harry Potter",
      date: "5 de Setembro de 2024",
      category: "Parque Temático",
      description: "Em 2025, o Universal Orlando Resort lançará uma nova área temática, The Wizarding World of Harry Potter – Ministry of Magic. Inspirada nos filmes 'Animais Fantásticos' e 'Harry Potter', essa área oferece experiências mágicas, incluindo uma das atrações mais inovadoras já criadas pela Universal.",
      image: NEWS4
    }
  ];

  // Função para converter a string de data para um objeto Date
  const convertDateStringToDate = (dateString: string) => {
    const [day, monthText, year] = dateString.split(" de ");
    const month = monthsMap[monthText];
    return new Date(Number(year), month, Number(day));
  };

  // Ordenar as notícias pela data
  const sortedNewsItems = newsItems.sort((a, b) => {
    const dateA = convertDateStringToDate(a.date);
    const dateB = convertDateStringToDate(b.date);
    return dateB.getTime() - dateA.getTime(); // Ordena de forma decrescente
  });

  const handleCardClick = (id: string) => {
    navigate(`/noticia/${id}`);
  };

  return (
    <div className="noticias-page">
      <Header activePage="noticias" />
      <div className="news-grid">
        {sortedNewsItems.map((item, index) => (
          <div key={index} className="news-card" onClick={() => handleCardClick(item.id)}>
            <img src={item.image} alt={item.title} className="news-image" />
            <div className="news-content">
              <span className="news-category">{item.category}</span>
              <h2 className="news-title">{item.title}</h2>
              <p className="news-description">{item.description}</p>
              <p className="news-date">{item.date}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Noticias;
