import React, { useEffect } from "react";
import Header from "../../Components/Header/Header";
import NEWS1 from "../../Assets/ImagensNews/News1/logo.webp";
import { FastAverageColor } from 'fast-average-color';
import "../../CSS/Global.css";
import "../../CSS/NoticiaDetalhada.css";

import IMG1 from "../../Assets/ImagensNews/News1/poster.webp";
import IMG2 from "../../Assets/ImagensNews/News1/PSPLUSHarrypotter.webp";
import IMG3 from "../../Assets/ImagensNews/News1/quadraDeQuadribol.webp";
import IMG4 from "../../Assets/ImagensNews/News1/ps1-hagrid-pose-1.webp";

const Noticia1: React.FC = () => {

  useEffect(() => {
    const images = document.querySelectorAll('.noticia-detalhada-image');

    images.forEach((image) => {
      (image as HTMLElement).style.transition = 'transform 0.2s ease, box-shadow 0.4s ease';

      const handleMouseMove = (e: MouseEvent) => {
        const fac = new FastAverageColor();
        const color = fac.getColor(image as HTMLImageElement);
        const rgbColor = `rgba(${color.value[0]}, ${color.value[1]}, ${color.value[2]}, 0.75)`;
        const rect = image.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const centerX = rect.width / 2;
        const centerY = rect.height / 2;

        const rotateX = ((y - centerY) / centerY) * -15;
        const rotateY = ((x - centerX) / centerX) * 15;

        const shadowX = ((x - centerX) / centerX) * 20;
        const shadowY = ((y - centerY) / centerY) * 20;

        (image as HTMLElement).style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
        (image as HTMLElement).style.boxShadow = `${shadowX}px ${shadowY}px 25px ${rgbColor}`;
      };

      const handleMouseLeave = () => {
        (image as HTMLElement).style.transform = 'perspective(1000px) rotateX(0) rotateY(0)';
        (image as HTMLElement).style.boxShadow = 'none';
      };

      image.addEventListener('mousemove', handleMouseMove as EventListener);
      image.addEventListener('mouseleave', handleMouseLeave as EventListener);

      return () => {
        image.removeEventListener('mousemove', handleMouseMove as EventListener);
        image.removeEventListener('mouseleave', handleMouseLeave as EventListener);
      };
    });
  }, []);

  return (
    <div className="noticia-detalhada-page">
      <Header activePage="noticias" />
      <div className="noticia-detalhada-content">
        <h1>Harry Potter: Quidditch Champions Já Está Disponível para Consoles e PC</h1>
        <p className="noticia-detalhada-date">3 de Setembro de 2024</p>
        <img
          src={NEWS1}
          alt="Harry Potter: Quidditch Champions Já Está Disponível"
          className="noticia-detalhada-image"
        />
        <div className="noticia-detalhada-body">
          <p>
            A Warner Bros. Games e a Unbroken Studios acabam de anunciar o lançamento oficial de <strong>Harry Potter: Quidditch Champions</strong>, 
            o aguardado jogo que traz a magia do Quidditch diretamente para os consoles e PCs. O jogo já está disponível nas plataformas <strong>PlayStation 5</strong>, 
            <strong>PlayStation 4</strong>, <strong>Xbox Series X|S</strong>, <strong>Xbox One</strong> e <strong>PC</strong> (via Steam e Epic Games Store) 
            nas versões <strong>Digital Standard</strong> e <strong>Deluxe</strong>. A edição física Deluxe será lançada em 8 de novembro de 2024. Para os fãs da Nintendo, 
            a versão para <strong>Nintendo Switch</strong> chega no período festivo de 2024. Além disso, a versão <strong>Standard Edition</strong> de Harry Potter: 
            Quidditch Champions será oferecida gratuitamente para membros do <strong>PlayStation Plus</strong> até o dia 30 de setembro de 2024.
          </p>

          <h2>Um Pedido Atendido: O Quidditch no Mundo de Harry Potter</h2>
          <p>
            David Haddad, presidente da Warner Bros. Games, expressou o entusiasmo da equipe com o lançamento:
            <i> "Ouvimos os pedidos apaixonados dos fãs por uma experiência de jogo focada no Quidditch, e com Harry Potter: Quidditch Champions estamos realizando o 
            sonho de trazer esse esporte mágico à vida. A equipe criou um jogo autêntico e estamos animados em permitir que os fãs realizem suas aspirações de se tornarem campeões de Quidditch."</i>
          </p>

          <img
            src={IMG1}
            alt="Campo de Quidditch em Harry Potter: Quidditch Champions"
            className="noticia-detalhada-image"
          />

          <h2>Uma Experiência Mágica e Cooperativa para os Fãs de Quidditch</h2>
          <p>
            Paul Ohanian, Diretor Executivo da Unbroken Studios, também comentou sobre o lançamento:
            <i>"Temos a honra de lançar Harry Potter: Quidditch Champions e compartilhar nossa visão deste querido esporte. Estamos incrivelmente entusiasmados em 
            permitir que os jogadores sobrevoem campos de Quidditch nunca antes vistos, juntem-se a amigos em modos cooperativos e competitivos e mergulhem nos detalhes mágicos do universo de Harry Potter."</i>
          </p>

          <img
            src={IMG2}
            alt="Modo Cooperativo em Harry Potter: Quidditch Champions"
            className="noticia-detalhada-image"
          />

          <p>
            O jogo oferece diversas opções de jogo, incluindo partidas solo, cooperativas online com amigos ou até competitivas de jogador contra jogador. Os fãs poderão se aventurar jogando nas posições tradicionais do Quidditch: <strong>Batedor</strong>, <strong>Artilheiro</strong>, <strong>Goleiro</strong> ou <strong>Apanhador</strong>.
          </p>

          <h2>Vantagens Exclusivas para Jogadores de Hogwarts Legacy</h2>
          <p>
            Como um bônus especial, os jogadores que possuem <strong>Hogwarts Legacy</strong> poderão receber o <strong>Bonus Legacy Pack</strong> ao baixar <strong>Harry Potter: Quidditch Champions</strong> e vincular sua conta WB Games. Esse pacote inclui a exclusiva <strong>Moontrimmer Broom Skin</strong> e traz o adorado personagem <strong>Sebastian Sallow</strong> como uma opção jogável.
          </p>

          <img
            src={IMG3}
            alt="Personalização de Campeões em Harry Potter: Quidditch Champions"
            className="noticia-detalhada-image"
          />

          <h2>Personalize seu Campeão de Quidditch ou Jogue com Personagens Icônicos</h2>
          <p>
            Em <strong>Harry Potter: Quidditch Champions</strong>, os jogadores terão a liberdade de criar seu próprio campeão personalizado, escolhendo habilidades e atributos para dominar os céus nos campos de Quidditch. Para aqueles que preferem jogar com personagens conhecidos, o jogo também oferece uma seleção de personagens icônicos do universo de <strong>Harry Potter</strong>, como <strong>Harry Potter</strong>, <strong>Ron Weasley</strong>, <strong>Draco Malfoy</strong>, entre outros.
          </p>

          <img
            src={IMG4}
            alt="Personagens Jogáveis em Harry Potter: Quidditch Champions"
            className="noticia-detalhada-image"
          />

          <p>
            O jogo promete uma experiência completa do <strong>mundo mágico</strong>, trazendo não apenas o esporte, mas também toda a atmosfera encantadora dos livros e filmes. Com gráficos avançados, mecânicas de voo realistas e jogabilidade envolvente, <strong>Harry Potter: Quidditch Champions</strong> é uma adição imperdível para os fãs da série e amantes de jogos de esportes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Noticia1;
