import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import "../CSS/Global.css";
import "../CSS/Forca.css";
import forcaWords from "../BD/forcaWords";
import forca0 from "../Assets/Forca/forca0.png";
import forca1 from "../Assets/Forca/forca1.png";
import forca2 from "../Assets/Forca/forca2.png";
import forca3 from "../Assets/Forca/forca3.png";
import forca4 from "../Assets/Forca/forca4.png";
import forca5 from "../Assets/Forca/forca5.png";
import forca6 from "../Assets/Forca/forca6.png";
import forca7 from "../Assets/Forca/forca7.png";
import CustomModal from "../Components/CustomModal/CustomModal";
import Notification from "../Components/Notification/notification";

const images = [forca0, forca1, forca2, forca3, forca4, forca5, forca6, forca7];

const Forca: React.FC = () => {
  const [word, setWord] = useState<string>(""); // A palavra atual
  const [guessedLetters, setGuessedLetters] = useState<string[]>([]); // Letras adivinhadas
  const [wrongGuesses, setWrongGuesses] = useState<number>(0); // Contagem de erros
  const [gameOver, setGameOver] = useState<boolean>(false); // Indica se o jogo acabou
  const [gameWon, setGameWon] = useState<boolean>(false); // Indica se o jogo foi vencido
  const [modalState, setModalState] = useState<{ isOpen: boolean; message: string }>({ isOpen: false, message: "" });
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationTimeout, setNotificationTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    startNewGame();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();
      if (/^[a-z]$/.test(key)) {
        handleLetterClick(key);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [guessedLetters, gameOver, gameWon]);

  const startNewGame = () => {
    const randomWord = forcaWords[Math.floor(Math.random() * forcaWords.length)];
    setWord(randomWord);
    setGuessedLetters([]);
    setWrongGuesses(0);
    setGameOver(false);
    setGameWon(false);
    setModalState({ isOpen: false, message: "" });

    // Resetar o estado das teclas do teclado
    document.querySelectorAll('.key').forEach((button) => {
      button.classList.remove('correct', 'incorrect');
      button.removeAttribute('disabled');
    });
  };

  const showErrorNotification = (message: string) => {
    setNotificationMessage(message);
    setShowNotification(false);

    if (notificationTimeout) {
      clearTimeout(notificationTimeout);
    }

    setTimeout(() => {
      setShowNotification(true);
      const timeout = setTimeout(() => {
        setShowNotification(false);
      }, 5000);

      setNotificationTimeout(timeout);
    }, 100);
  };

  const handleLetterClick = (letter: string) => {
    if (guessedLetters.includes(letter)) {
      showErrorNotification(`Letra "${letter.toUpperCase()}" já foi usada.`);
      return;
    }

    const newGuessedLetters = [...guessedLetters, letter];
    setGuessedLetters(newGuessedLetters);

    if (word.includes(letter)) {
      const allGuessed = word.split("").every((char) => newGuessedLetters.includes(char));
      if (allGuessed) {
        setGameWon(true);
        setModalState({ isOpen: true, message: "Parabéns! Você venceu!" });
      }
    } else {
      setWrongGuesses((prev) => {
        const newWrongGuesses = prev + 1;
        if (newWrongGuesses >= 7) {
          setGameOver(true);
          setModalState({ isOpen: true, message: `Fim de jogo! A palavra era "${word}".` });
        }
        return newWrongGuesses;
      });
    }

    // Atualiza a cor das teclas clicadas
    const button = document.getElementById(`key-${letter}`);
    if (button) {
      if (word.includes(letter)) {
        button.classList.add("correct");
      } else {
        button.classList.add("incorrect");
      }
      button.setAttribute('disabled', 'true');
    }
  };

  const renderWord = () => {
    return word.split("").map((letter, index) => (
      <span key={index} className="letter">
        {guessedLetters.includes(letter) ? letter : "_"}
      </span>
    ));
  };

  return (
    <div className="forca-game">
      <div className="header">
        <Header activePage="forca" />
      </div>

      <div className="title">
        <h1>Forca</h1>
      </div>

      <div className="forca-container">
        <img src={images[wrongGuesses]} alt={`Forca - Erros: ${wrongGuesses}`} className="forca-image" />
        <div className="word-container">{renderWord()}</div>
        <div className="keyboard">
          <div className="keyboard-row">
            {"qwertyuiop".split("").map((letter) => (
              <button
                key={letter}
                id={`key-${letter}`}
                onClick={() => handleLetterClick(letter)}
                disabled={gameOver || gameWon}
                className="key"
              >
                {letter.toUpperCase()}
              </button>
            ))}
          </div>
          <div className="keyboard-row">
            <div className="spacer-half"></div>
            {"asdfghjkl".split("").map((letter) => (
              <button
                key={letter}
                id={`key-${letter}`}
                onClick={() => handleLetterClick(letter)}
                disabled={gameOver || gameWon}
                className="key"
              >
                {letter.toUpperCase()}
              </button>
            ))}
            <div className="spacer-half"></div>
          </div>
          <div className="keyboard-row">
            {"zxcvbnm".split("").map((letter) => (
              <button
                key={letter}
                id={`key-${letter}`}
                onClick={() => handleLetterClick(letter)}
                disabled={gameOver || gameWon}
                className="key"
              >
                {letter.toUpperCase()}
              </button>
            ))}
          </div>
        </div>
      </div>

      <CustomModal
        isOpen={modalState.isOpen}
        message={modalState.message}
        onPlayAgain={startNewGame}
      />

      {showNotification && (
        <Notification
          message={notificationMessage}
          type="error"
          onClose={() => setShowNotification(false)}
        />
      )}
    </div>
  );
};

export default Forca;
