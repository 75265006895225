const forcaWords: string[] = [
    "harry",
    "hermione",
    "ron",
    "dumbledore",
    "voldemort",
    "hagrid",
    "snape",
    "sirius",
    "lupin",
    "draco",
    "ginny",
    "luna",
    "neville",
    "mcgonagall",
    "trelawney",
    "filch",
    "pomfrey",
    "lockhart",
    "bellatrix",
    "malfoy",
    "lucius",
    "narcissa",
    "dobby",
    "kreacher",
    "winky",
    "hedwig",
    "fawkes",
    "aragog",
    "fang",
    "norberto",
    "fluffy",
    "basilisco",
    "triwizard",
    "quiddich",
    "azkaban",
    "hogwarts",
    "durmstrang",
    "beauxbatons",
    "gringotts",
    "godric's",
    "hogsmeade",
    "diagon",
    "knockturn",
    "privet",
    "grimmauld",
    "pivet",
    "ministério",
    "auror",
    "alohomora",
    "expelliarmus",
    "expecto",
    "patronum",
    "accio",
    "kedavra",
    "imperio",
    "lumos",
    "nox",
    "obliviate",
    "petrificus",
    "totalus",
    "wingardium",
    "leviosa",
    "riddikulus",
    "stupefy",
    "sectumsempra",
    "legilimens",
    "oclumência",
    "amortentia",
    "polissuco",
    "veritaserum",
    "felix",
    "felicis",
    "essência",
    "murtisco",
    "poção",
    "cura",
    "feridas",
    "amortentia",
    "mandrágora",
    "pimentinhas",
    "agridoce",
    "floreios",
    "borroes",
    "mapa",
    "maroto",
    "professor",
    "aula",
    "defesa",
    "artes",
    "trevas",
    "herbologia",
    "transfiguração",
    "feitiçaria",
    "pocões",
    "quadribol",
    "varinha",
    "varinhas",
    "olivaras",
    "bicuço",
    "aguamenti",
    "colloportus",
    "deletrius",
    "diffindo",
    "episkey",
    "evanesco",
    "incendio",
    "legilimens",
    "locomotor",
    "mortis",
    "morsmordre",
    "periculum",
    "protego",
    "quietus",
    "reducio",
    "reduto",
    "relashio",
    "rennervate",
    "reparo",
    "scourgify",
    "silencio",
    "sonorus",
    "tarantallegra",
    "verdimillious",
    "volate",
    "ascendare",
    "arestomomentum",
    "arresto",
    "momentum",
    "bombarda",
    "caput",
    "draconis",
    "confringo",
    "conjunctivitus",
    "defodio",
    "dissendium",
    "engorgio",
    "expulso",
    "finestra",
    "flagrate",
    "flipendo",
    "furnunculus",
    "gargalheto",
    "glisseo",
    "homenum",
    "incarcerous",
    "incendio",
    "leviso",
    "locomotor",
    "lumos",
    "mobiliabrum",
    "mobilicorpus",
    "molliare",
    "muffliato",
    "nox",
    "obscuro",
    "oclumência",
    "oppugno",
    "periculum",
    "petrificus",
    "portrificus",
    "rictusempra",
    "serpensortia",
    "tergeo",
    "vulnera",
    "sanentur",
    "revelio",
    "expelliarmus",
    "alohomora",
    "levicorpus",
    "protego",
    "ridikkulus",
    "ferula",
    "imperio",
    "avada",
    "sectumsempra",
    "crucio",
    "imperius",
    "fiendfyre",
    "riddikulus",
    "alohomora",
    "alastor",
    "crookshanks",
    "pigwidgeon",
    "scabbers",
    "grawp",
    "dementador",
    "hinkypunk",
    "grindylow",
    "banshee",
    "kelpie",
    "hipogrifo",
    "troll",
    "duende",
    "gnomo",
    "lobisomem",
    "fenix",
    "bicho-papão",
    "estrige",
    "mantícora",
    "niffler",
    "puffskein",
    "ronin",
    "merfolk",
    "thestral",
    "unicórnio",
    "dragão",
    "horcrux",
    "profecia",
    "patrono",
    "animagus",
    "metamorfomago",
    "oclumência",
    "legilimência",
    "veritaserum",
    "potion",
    "polyjuice",
    "felix",
    "felicis",
    "elixir",
    "espelho",
    "oveja",
    "black",
    "moody",
    "pichitcho"
  ];

export default forcaWords;
