import React, { useEffect } from "react";
import Header from "../../Components/Header/Header";
import NEWS4 from "../../Assets/ImagensNews/ministryofmagic.webp";
import { FastAverageColor } from 'fast-average-color';
import "../../CSS/Global.css";
import "../../CSS/NoticiaDetalhada.css";

import IMG1 from "../../Assets/ImagensNews/News4/inicio.webp";
import IMG2 from "../../Assets/ImagensNews/News4/entrada2.webp";
import IMG3 from "../../Assets/ImagensNews/News4/corrida.webp";
import IMG4 from "../../Assets/ImagensNews/News4/circo.webp";
import IMG5 from "../../Assets/ImagensNews/News4/loja.webp";
import IMG6 from "../../Assets/ImagensNews/News4/varinhas.webp";
import IMG7 from "../../Assets/ImagensNews/News4/entrada2.webp";
import IMG8 from "../../Assets/ImagensNews/News4/cafe.webp";
import IMG9 from "../../Assets/ImagensNews/News4/beco.webp";
import IMG10 from "../../Assets/ImagensNews/News4/umbridge.webp";

const Noticia4: React.FC = () => {
  
  useEffect(() => {
    const images = document.querySelectorAll('.noticia-detalhada-image');
    
    images.forEach((image) => {
      // Extraindo a cor predominante da imagem
      
      (image as HTMLElement).style.transition = 'transform 0.2s ease, box-shadow 0.4s ease';
      
      const handleMouseMove = (e: MouseEvent) => {
        const fac = new FastAverageColor();
        const color = fac.getColor(image as HTMLImageElement);
        const rgbColor = `rgba(${color.value[0]*2}, ${color.value[1]*2}, ${color.value[2]*2}, 0.75)`; // Cor dinâmica com opacidade
        const rect = image.getBoundingClientRect();
        const x = e.clientX - rect.left; // Posição X do mouse em relação à imagem
        const y = e.clientY - rect.top;  // Posição Y do mouse em relação à imagem
  
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;
  
        // Mantendo o espaço de movimento fixo
        const rotateX = ((y - centerY) / centerY) * -15; // Controla a rotação no eixo X
        const rotateY = ((x - centerX) / centerX) * 15;  // Controla a rotação no eixo Y
  
        // Calcula a intensidade da sombra com a cor predominante
        const shadowX = ((x - centerX) / centerX) * 20; // Controla a posição da sombra no eixo X
        const shadowY = ((y - centerY) / centerY) * 20; // Controla a posição da sombra no eixo Y
  
        (image as HTMLElement).style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
        (image as HTMLElement).style.boxShadow = `${shadowX}px ${shadowY}px 25px ${rgbColor}`;
      };
  
      const handleMouseLeave = () => {
        (image as HTMLElement).style.transform = 'perspective(1000px) rotateX(0) rotateY(0)';
        (image as HTMLElement).style.boxShadow = 'none';
      };
  
      image.addEventListener('mousemove', handleMouseMove as EventListener);
      image.addEventListener('mouseleave', handleMouseLeave as EventListener);
  
      return () => {
        image.removeEventListener('mousemove', handleMouseMove as EventListener);
        image.removeEventListener('mouseleave', handleMouseLeave as EventListener);
      };
    });
  }, []);
  
  

  return (
    <div className="noticia-detalhada-page">
      <Header activePage="noticias" />
      <div className="noticia-detalhada-content">
        <h1>
          Universal Orlando Lança Nova Área Temática Inspirada em Harry Potter
        </h1>
        <p className="noticia-detalhada-date">5 de Setembro de 2024</p>
        <img
          src={NEWS4}
          alt="Universal Orlando Lança Nova Área Temática Inspirada em Harry Potter"
          className="noticia-detalhada-image"
        />
        <div className="noticia-detalhada-body">
          <p>
            O Universal Orlando Resort revelou novos detalhes sobre The
            Wizarding World of Harry Potter – Ministry of Magic, que chegará ao
            Universal Epic Universe em 2025.
          </p>
          <p>
            Os visitantes poderão descobrir diferentes eras do The Wizarding
            World em um mundo temático totalmente novo, inspirado nos adorados
            filmes “Animais Fantásticos” e “Harry Potter”.
          </p>
          <p>
            Em 2025, o Universal Orlando Resort lançará uma nova área temática
            com a temática de Harry Potter, com The Wizarding World of Harry
            Potter – Ministry of Magic, um dos cinco mundos que chegarão ao
            Universal Epic Universe.
          </p>
          <p>
            Situada na mágica Paris dos filmes Animais Fantásticos da Warner
            Bros. Pictures e no Ministério da Magia britânico da série de filmes
            Harry Potter, o The Wizarding World of Harry Potter – Ministry of
            Magic apresenta uma coleção inspiradora de experiências que incluem
            restaurantes fantásticos, lojas extravagantes, entretenimento
            fascinante e a atração mais ambiciosa e inovadora que a Universal já
            criou.
          </p>
          <p>
            Desenvolvido pela premiada equipe da Universal Creative e pela
            Warner Bros. Discovery Global Themed Entertainment, o The Wizarding
            World of Harry Potter – Ministry of Magic se baseia no fenômeno
            global que começou em 2010 com a estreia do The Wizarding World of
            Harry Potter – Hogsmeade no Universal Islands of Adventure e que
            continuou com o lançamento em 2014 do The Wizarding World of Harry
            Potter – Diagon Alley no Universal Studios Florida. No próximo ano,
            o nível de entretenimento dos parques temáticos será elevado mais
            uma vez com a adição do The Wizarding World of Harry Potter –
            Ministry of Magic, criando uma trilogia emocionante de aventuras
            mágicas no Universal Orlando.
          </p>

          <img
            src={IMG1}
            alt="Universal Orlando Lança Nova Área Temática Inspirada em Harry Potter"
            className="noticia-detalhada-image"
          />

          <h2>CRIATURAS MÁGICAS E O MINISTÉRIO O AGUARDA...</h2>
          <p>
            Além do portal ornamentado deste novo mundo, os visitantes viajarão
            primeiro de um parque Trouxa em Paris para o espetáculo de tirar o
            fôlego da Place Cachée de 1920 – um distrito comercial escondido e
            movimentado na Paris dos bruxos, repleto de edifícios
            Haussmannianos, lojas, cafés nas calçadas e cúpulas parisienses
            históricas que se erguem à distância.
          </p>
          <p>
            Aqui, bruxos e não-mágicos ficarão encantados com a grandeza
            majestosa e o ambiente encantador da cidade enquanto lançam feitiços
            deslumbrantes pelas ruas mágicas usando varinhas interativas,
            encontram feras fantásticas em um circo itinerante e – pela primeira
            vez no The Wizarding World of Harry Potter – viajam através dos
            países e do tempo para a Londres dos anos 90 usando o Métro-Floo
            para uma aventura emocionante dentro do icônico Ministério da Magia
            Britânico.
          </p>
          <p>
            The Wizarding World of Harry Potter – Ministry of Magic contará com
            os níveis inigualáveis de imersão e atenção aos detalhes que os fãs
            esperam do The Wizarding World of Harry Potter.
          </p>

          <img
            src={IMG2}
            alt="Universal Orlando Lança Nova Área Temática Inspirada em Harry Potter"
            className="noticia-detalhada-image"
          />

          <h2>Novas e empolgantes maravilhas a serem descobertas</h2>
          <p>
            <strong>Harry Potter and the Battle at the Ministry:</strong> Com
            uma combinação única de ambientes amplos, narrativa poderosa e
            tecnologia inédita, essa atração será uma das mais impressionantes
            já criadas. A aventura começa quando os visitantes viajam pelo
            Métro-Floo para o tão esperado julgamento de Dolores Umbridge no
            Ministério da Magia Britânico. Lá, os visitantes se juntarão a Harry
            Potter, Ron Weasley, Hermione Granger e um elfo doméstico em uma
            perseguição emocionante pelo Ministério, enquanto tentam capturar
            Umbridge e escapar de perigos ao longo do caminho.
          </p>

          <img
            src={IMG3}
            alt="Universal Orlando Lança Nova Área Temática Inspirada em Harry Potter"
            className="noticia-detalhada-image"
          />

          <p>
            <strong>Le Cirque Arcanus:</strong> Uma experiência teatral ao vivo,
            com artistas ao vivo, marionetes incríveis, efeitos especiais
            brilhantes e muito mais, onde os visitantes encontrarão feras
            fantásticas como nunca antes. O show acompanha o Ringmaster Skender,
            que roubou a maleta de Newt Scamander, tentando trazer o falido Le
            Cirque Arcanus de volta à sua antiga glória.
          </p>

          <img
            src={IMG4}
            alt="Universal Orlando Lança Nova Área Temática Inspirada em Harry Potter"
            className="noticia-detalhada-image"
          />

          <p>
            <strong>Cosme Acajor Baguettes Magique:</strong> Na principal loja
            de varinhas de Paris, os visitantes poderão comprar algumas das
            melhores varinhas do mundo, projetadas pelo estimado fabricante
            artesanal de varinhas Cosme Acajor.
          </p>

          <img
            src={IMG5}
            alt="Universal Orlando Lança Nova Área Temática Inspirada em Harry Potter"
            className="noticia-detalhada-image"
          />

          <p>
            <strong>Inspiring Wand Magic:</strong> Enquanto exploram as ruas da
            Paris mágica, os visitantes podem usar suas varinhas interativas
            para lançar feitiços, interagir com feras fantásticas e se envolver
            com objetos encantados em muitas das vitrines das lojas.
          </p>

          <img
            src={IMG6}
            alt="Universal Orlando Lança Nova Área Temática Inspirada em Harry Potter"
            className="noticia-detalhada-image"
          />

          <h2>Entretenimento cativante</h2>
          <p>
            Os visitantes também poderão encontrar outros personagens do mundo
            mágico, incluindo estudantes de intercâmbio de Hogwarts e
            Ilvermorny, Aurores do Ministère des Affaires Magiques de la France,
            e retratos falantes que conversam com os moradores locais sobre os
            acontecimentos na Place Cachée.
          </p>

          <img
            src={IMG7}
            alt="Universal Orlando Lança Nova Área Temática Inspirada em Harry Potter"
            className="noticia-detalhada-image"
          />

          <h2>Restaurantes e Lojas Temáticas</h2>
          <p>
            <strong>Café L’Air De La Sirène:</strong> Uma charmosa cafeteria
            onde os visitantes podem se deliciar com sanduíches franceses,
            pratos do dia e sobremesas, em um ambiente adornado com mármores
            esculpidos e detalhes de feras fantásticas.
          </p>

          <img
            src={IMG8}
            alt="Universal Orlando Lança Nova Área Temática Inspirada em Harry Potter"
            className="noticia-detalhada-image"
          />

          <p>
            <strong>Le Gobelet Noir:</strong> Uma hospedaria sombria onde bruxos
            e bruxas das trevas internacionais se reúnem para escapar dos
            olhares curiosos do Ministério de Paris e fazer uma refeição
            reservada.
          </p>

          <img
            src={IMG9}
            alt="Universal Orlando Lança Nova Área Temática Inspirada em Harry Potter"
            className="noticia-detalhada-image"
          />

          <p>
            <strong>Bar Moonshine:</strong> Um bar onde bruxos podem desfrutar
            de cervejas, vinhos e coquetéis exclusivos, rodeados por itens da
            cultura americana.
          </p>

          <p>
            <strong>Bièraubeurre Cart:</strong> Nenhuma visita ao The Wizarding
            World of Harry Potter está completa sem a Butterbeer! Os visitantes
            podem parar no carrinho Bièraubeurre para tomar uma caneca gelada da
            bebida favorita dos fãs.
          </p>
          <p>
            Os visitantes também poderão comprar equipamentos e lembranças dos
            bruxos na Les Galeries Mirifiques, satisfazer suas vontades de comer
            doces na confeitaria francesa K. Rammelle, ou comprar itens
            inspirados no Ministério da Magia na loja de presentes do
            Métro-Floo, Tour En Floo.
          </p>
          <p>
            Os visitantes empolgados com o The Wizarding World of Harry Potter –
            Ministry of Magic podem comprar novos produtos inspirados nesse
            mundo temático no Universal Epic Universe Preview Center no
            Universal CityWalk ou no site shop.universalorlando.com.
          </p>

          <img
            src={IMG10}
            alt="Universal Orlando Lança Nova Área Temática Inspirada em Harry Potter"
            className="noticia-detalhada-image"
          />
        </div>
      </div>
    </div>
  );
};

export default Noticia4;
