import React, { useEffect, useRef } from "react";
import Header from "../Components/Header/Header";
import "../CSS/Global.css";
import "../CSS/Pomo.css";
import pomoImage from "../Assets/pomo.png";

const PegueOPomo: React.FC = () => {
  const pomoRef = useRef<HTMLImageElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (isTouchScreen()) {
      alert("Touch screen not allowed");
      return;
    }

    const handleMouseEnter = () => {
      if (arrowRef.current) {
        arrowRef.current.style.opacity = "0";
        setTimeout(() => {
          if (arrowRef.current) arrowRef.current.style.display = "none";
        }, 500);
      }

      if (pomoRef.current && wrapperRef.current) {
        let color1 = Math.floor(Math.random() * 206) + 50;
        let color2 = Math.floor(Math.random() * 206) + 50;
        let color3 = Math.floor(Math.random() * 206) + 50;
        let top = Math.floor(Math.random() * 101);
        let left = Math.floor(Math.random() * 101);

        // Previne que o pomo vá muito próximo das bordas
        if (top < 5) top += 5;
        else if (top > 95) top -= 5;
        if (left < 5) left += 5;
        else if (left > 95) left -= 5;

        pomoRef.current.style.cssText = `
          top: ${top}%;
          left: ${left}%;
          filter: drop-shadow(1px 1px 9px rgb(${color1}, ${color2}, ${color3}));
        `;
        wrapperRef.current.style.cssText = `
          top: calc(${top}% + 5px);
          left: calc(${left}% + 5px);
        `;
      }
    };

    const handleClick = (e: MouseEvent) => {
      if (e.isTrusted) {
        alert("Você me pegou!");
      } else {
        alert("Ei, isso é trapaça!");
      }
    };

    const pomoElement = pomoRef.current;
    const wrapperElement = wrapperRef.current;

    if (wrapperElement && pomoElement) {
      wrapperElement.addEventListener("mouseenter", handleMouseEnter);
      pomoElement.addEventListener("click", handleClick);
    }

    return () => {
      if (wrapperElement && pomoElement) {
        wrapperElement.removeEventListener("mouseenter", handleMouseEnter);
        pomoElement.removeEventListener("click", handleClick);
      }
    };
  }, []);

  const isTouchScreen = () => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      (navigator as any).msMaxTouchPoints > 0
    );
  };

  return (
    <div>
      <div className="header">
        <Header activePage="pegue-o-pomo" />
      </div>

      <div className="title">
        <h1>Pegue o Pomo</h1>
      </div>

      <span id="arrow" ref={arrowRef}>&larr;</span>
      <div id="wrapper" ref={wrapperRef}>
        <img id="dot" ref={pomoRef} src={pomoImage} alt="Pomo de Ouro" />
      </div>
    </div>
  );
};

export default PegueOPomo;
