import React from "react";
import "./CustomModal.css";

interface CustomModalProps {
  isOpen: boolean;
  message: string;
  onPlayAgain: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({ isOpen, message, onPlayAgain }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{message}</h2>
        <button onClick={onPlayAgain} className="modal-button">
          Jogar Novamente
        </button>
      </div>
    </div>
  );
};

export default CustomModal;
