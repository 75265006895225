import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import "../../CSS/Global.css";
import "../../CSS/Quiz.css";

const harryPotterQuestions = [
  // Perguntas relacionadas a "Harry Potter e a Câmara Secreta"
    {
      question: "Quem é o novo professor de Defesa Contra as Artes das Trevas em 'A Câmara Secreta'?",
      options: ["Severo Snape", "Gilderoy Lockhart", "Remo Lupin", "Quirino Quirrell"],
      answer: 1,
    },
    {
      question: "Qual criatura Hagrid cuida e é encontrada em uma caixa de biscoitos?",
      options: ["Acromântula", "Basilisco", "Hipogrifo", "Arpéu"],
      answer: 0,
    },
    {
      question: "Quem é o responsável por abrir a Câmara Secreta pela primeira vez?",
      options: ["Tom Riddle", "Lucius Malfoy", "Draco Malfoy", "Severo Snape"],
      answer: 0,
    },
    {
      question: "Qual é o nome do elfo doméstico que tenta avisar Harry sobre o perigo em Hogwarts?",
      options: ["Winky", "Dobby", "Kreacher", "Hokey"],
      answer: 1,
    },
    {
      question: "Quem transforma Harry em um suspeito por ser capaz de falar com cobras?",
      options: ["Draco Malfoy", "Severo Snape", "Tom Riddle", "Gilderoy Lockhart"],
      answer: 2,
    },
    {
      question: "Qual objeto é usado por Tom Riddle para manipular Ginny Weasley?",
      options: ["Um diário", "Um colar", "Uma varinha", "Um anel"],
      answer: 0,
    },
    {
      question: "Qual criatura é encontrada na Câmara Secreta?",
      options: ["Acromântula", "Dragão", "Basilisco", "Hipogrifo"],
      answer: 2,
    },
    {
      question: "Qual é a poção que Hermione prepara para descobrir quem está por trás dos ataques?",
      options: ["Poção Polissuco", "Poção do Amor", "Poção Herbicida", "Poção da Verdade"],
      answer: 0,
    },
    {
      question: "Qual parte do corpo de Hermione é transformada devido a um erro com a Poção Polissuco?",
      options: ["Mãos", "Rabo de gato", "Olhos", "Nariz"],
      answer: 1,
    },
    {
      question: "Quem é o verdadeiro herdeiro de Sonserina?",
      options: ["Draco Malfoy", "Tom Riddle", "Harry Potter", "Gilderoy Lockhart"],
      answer: 1,
    },
    {
      question: "Como Hagrid é capturado e levado para Azkaban?",
      options: ["Acusado de liberar o basilisco", "Descoberto cuidando de uma acromântula", "Suspeito de abrir a Câmara", "Pegando um aluno na Floresta Proibida"],
      answer: 2,
    },
    {
      question: "Quem escreve uma mensagem de ameaça na parede de Hogwarts com sangue?",
      options: ["Dobby", "Tom Riddle", "Ginny Weasley", "Harry Potter"],
      answer: 2,
    },
    {
      question: "Qual professor perde a memória ao tentar apagar as memórias de Harry e Ron?",
      options: ["Gilderoy Lockhart", "Severo Snape", "Remo Lupin", "Minerva McGonagall"],
      answer: 0,
    },
    {
      question: "Qual é o nome completo de Tom Riddle?",
      options: ["Tom Marvolo Riddle", "Tom Lúcio Riddle", "Tom Severo Riddle", "Tom Arthur Riddle"],
      answer: 0,
    },
    {
      question: "O que acontece com a Fawkes, a fênix de Dumbledore, quando ela é atingida pelo basilisco?",
      options: ["Fica cega", "Morre e renasce das cinzas", "Fica mais poderosa", "Perde todas as penas"],
      answer: 1,
    },
  ];

const HarryPotterChamberQuiz: React.FC = () => {
  const [shuffledQuestions, setShuffledQuestions] = useState<any[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [score, setScore] = useState(0);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [isQuizFinished, setIsQuizFinished] = useState(false);

  function shuffleOptions(options: string[]): string[] {
    const shuffledOptions = [...options];
    for (let i = shuffledOptions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledOptions[i], shuffledOptions[j]] = [shuffledOptions[j], shuffledOptions[i]];
    }
    return shuffledOptions;
  }

  useEffect(() => {
    const shuffled = harryPotterQuestions.map((question) => {
      const options = shuffleOptions(question.options);
      const correctAnswerIndex = options.indexOf(question.options[question.answer]);
      return {
        ...question,
        options,
        answer: correctAnswerIndex,
      };
    });
    setShuffledQuestions(shuffled);
  }, []);

  const handleOptionSelect = (index: number) => {
    if (!isAnswerSubmitted) {
      setSelectedOption(index);
    }
  };

  const handleSubmitAnswer = () => {
    if (selectedOption !== null) {
      const isCorrect = selectedOption === shuffledQuestions[currentQuestionIndex].answer;
      if (isCorrect) {
        setScore((prevScore) => prevScore + 1);
      }
      setIsAnswerSubmitted(true);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < shuffledQuestions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedOption(null);
      setIsAnswerSubmitted(false);
    } else {
      setIsQuizFinished(true);
    }
  };

  const handlePlayAgain = () => {
    window.location.reload(); // Recarrega a página para reiniciar o quiz
  };

  const handleGoBack = () => {
    window.location.href = "/quiz"; // Redireciona para a rota "/quiz"
  };

  return (
    <div>
      <div className="header">
        <Header activePage="quiz" />
      </div>

      <div className="quiz-container">
        {!isQuizFinished ? (
          shuffledQuestions.length > 0 && (
            <>
              <h2>{shuffledQuestions[currentQuestionIndex].question}</h2>
              <div className="options">
                {shuffledQuestions[currentQuestionIndex].options.map(
                  (option: string, index: number) => (
                    <button
                      key={index}
                      className={`option-button ${
                        selectedOption === index ? "selected" : ""
                      } ${
                        isAnswerSubmitted
                          ? index === shuffledQuestions[currentQuestionIndex].answer
                            ? "correct"
                            : index === selectedOption
                            ? "incorrect"
                            : ""
                          : ""
                      }`}
                      onClick={() => handleOptionSelect(index)}
                      disabled={isAnswerSubmitted}
                    >
                      {option}
                    </button>
                  )
                )}
              </div>
              {isAnswerSubmitted ? (
                <button className="next-button" onClick={handleNextQuestion}>
                  Próxima
                </button>
              ) : (
                <button
                  className="submit-button"
                  onClick={handleSubmitAnswer}
                  disabled={selectedOption === null}
                >
                  Confirmar
                </button>
              )}
            </>
          )
        ) : (
          <div className="quiz-modal">
            <div className="quiz-modal-content">
              <h2>Quiz Concluído!</h2>
              <p>Sua pontuação: {((score / shuffledQuestions.length) * 100).toFixed(2)}%</p>
              <div className="modal-buttons">
                <button onClick={handlePlayAgain} className="modal-button">
                  Jogar Novamente
                </button>
                <button onClick={handleGoBack} className="modal-button">
                  Voltar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HarryPotterChamberQuiz;
